import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import logo from "../../../assets/images/header-img.png";
import axios from "axios";
import config from "../../../config";
import { Spin, Space } from "antd";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import { calculateColumnTotal } from "../../Shared/Utility";
import pdfStyles from "../../../Pdf/pdfStyles"
import PrintFooter from "../../../Pdf/PrintFooter"
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

function PrintPurchaseList() {
  const [listofPurchase, SetlistofPurchase] = useState([]);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const [loading, setLoading] = useState(false);
  const Email_Address = localStorage.getItem("Email_Address");
  const Mobile_No = localStorage.getItem("Mobile_No");
  const PumpName = localStorage.getItem("PumpName");
  const today = new Date();
  const date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

  const [CashTotal, setCashTotal] = useState(0);
  const [CreditTotal, setCreditTotal] = useState(0);
  const [TotalPurchases, setTotalPurchases] = useState(0);
  const urlParams = new URLSearchParams(window.location.search);
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: 1,
      PageSize: 10000,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/GetPurchases",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofPurchase(response.data.listofPurchase);
          setCashTotal(response.data.CashTotal);
          setCreditTotal(response.data.CreditTotal);
          setTotalPurchases(response.data.TotalPurchases);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const CreditsTotal = calculateColumnTotal(listofPurchase, "Credit_Total");
  const Cashtotal = calculateColumnTotal(listofPurchase, "Cash_Total");
  const total = parseFloat(CreditTotal) + parseFloat(CashTotal);

  const generateData = () => {
    if (listofPurchase.length > 0) {
      let cashSum = 0;
      let creditSum = 0;
      return listofPurchase.map((item, index) => {
        cashSum += item.Cash_Total;
        creditSum += item.Credit_Total;
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 60 }]}>
              <Text style={pdfStyles.tdValue}>{item.InvoiceNo}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90 }]}>
              <Text style={pdfStyles.tdValue}>
                {moment(item.Dated).format(config.date_format)}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.Description}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Cash_Total,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Credit_Total,2)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 90, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
              {PrintFormateNumber(item.Cash_Total + item.Credit_Total,0)}
              </Text>
            </View>
          </View>
         
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>PURCHASE VIEW REPORT</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Invoice No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Cash</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
            </View>
            {generateData()}
            <View style={pdfStyles.tableHead}>
              <View
                style={[pdfStyles.th, { width: 320, textAlign: "right" }]}
              >
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {" "}
                  {PrintFormateNumber(calculateColumnTotal(listofPurchase, "Cash_Total"),0)}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>
                  {PrintFormateNumber(calculateColumnTotal(listofPurchase, "Credit_Total"),2)}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 90, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(total,0)}</Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintPurchaseList;
