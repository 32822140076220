import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import Header from '../Layout/Header'
import axios from 'axios';
import config from '../../config';
import { Row, Col, Card, Table, Space, Statistic, Spin, Breadcrumb } from 'antd';
import { Link, NavLink } from 'react-router-dom';



function Stock() {
    // const Voucherslist = listofVouchers.slice(startIndex, endIndex)


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [loading, setLoading] = useState(false);
    const [ListofStockDetails, SetListofStockDetails] = useState([]);
    const [ListofReadings, SetListofReadings] = useState([]);

    useEffect(() => {
        setLoading(true)
        const data = {
            "UserID": UserID,
            "AccessKey": AccessKey
        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        console.log(data);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    setLoading(false)

                    SetListofStockDetails(response.data.ListofStockDetails);
                    SetListofReadings(response.data.ListofReadings);

                } else {
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });




    }, []);

    const columns = [
        {
            title: 'Type',
            key: 'ProductName',
            dataIndex: 'ProductName',
        },
        {
            title: 'Machine No',
            key: 'Pump_No',
            dataIndex: 'Pump_No',
        },
        {
            title: 'Reading',
            key: 'Last_Reading',
            dataIndex: 'Last_Reading',
            render: (text, record) => (
                <Link to={`/products/manage-machines/${record.id}`}>{text}</Link>
            ),
        }
    ]

    return (
        <>

            <div className="sub-menu-wrap">
                <h5>Dashboard</h5>
                <DashboardMenu />
            </div>
            <div className="right-side-contents">
                <Header title="Stocks" />
                <div className='page-content'>
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/dashboard/analytics">Dashboard</NavLink>,
                            },
                            {
                                title: 'Stock',
                            },
                        ]}
                    />
                    {loading ? <div className='spin-center'> <Space size="large">  <Spin size="large" /> </Space> </div> :
                        <div className='stocks mt-4'>
                            <Row gutter={10}>
                                <Col span={12}>
                                    <Card title={
                                        <div className='card-title-icon'>
                                            <span className="material-symbols-outlined  mr-2">
                                                trending_up
                                            </span>Stock Details
                                        </div>
                                    }>

                                        <Row gutter={[19, 16]}>
                                            {ListofStockDetails?.map((stock) => (
                                                <Col span={12}>
                                                    <div className='icon-box'>
                                                        <div className='icon'></div>
                                                        <div className='info'>
                                                            <a href='/reports/account-ledger'> <p>{stock.Name}</p></a>
                                                            <h2>{stock.Balance}</h2>
                                                            {/* <h2>
                                                                {stock.BalanceType === 'Debit' ? '' : '-'}{stock.Balance}
                                                            </h2> */}
                                                            <p>{stock.Measure_Unit_BE?.Name}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Card>
                                </Col>
                                <Col span={12}>
                                    <Card>
                                        <div className='card-body'>
                                            <h4 className='card-title'>Machine Readings</h4>
                                            <Table
                                                bordered
                                                size="small"
                                                columns={columns}
                                                dataSource={ListofReadings}
                                                loading={loading}
                                                pagination={false}

                                            />
                                        </div>
                                    </Card>
                                </Col>

                            </Row>
                        </div>
                    }
                </div>
            </div>

        </>
    )
}

export default Stock
