import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import logo from "../../assets/images/Logo/Logo.svg";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, message, Form, Input, Spin } from "antd";
import { KeyOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

const AdminLogin = () => {

  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const AccessKey = localStorage.getItem("AccessKey");
  const ID = localStorage.getItem("ID");
  const [pageLoaded, setPageLoaded] = useState(false);

  let navigate = useNavigate();


  useEffect(() => {
    document.title = "Admin Login";
    setTimeout(() => {
      setPageLoaded(true);
    }, 2000);
  }, []);

  const handleSubmit = (formData) => {
    setLoading(true);
    
    const data = {
       MemberType: "Website",
       ...formData
    }
    
    console.log(formData);


    var api_config = {
      method: "post",
      url: config.base_url + "AdminUser/SignIn",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {

          localStorage.setItem("AdminAccessKey", response.data.AccessKey);
          localStorage.setItem("AdminID", response.data.ID);
          localStorage.setItem("AdminFull_Name", response.data.Full_Name);
          localStorage.setItem("MemberType", "Admin");
          navigate("/controlbase/dashboard")

          setLoading(false);
          //
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  

  return (
    <>
      {contextHolder}
      {!pageLoaded ? (
        <div
          className="loader-container"
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <div className="auth-wrap">

          <div className="auth-head">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <h1>Admin Login</h1>
          </div>
          <div className="auth-card">
            <Form
              name="auth-form"
              className="auth-form"
              size="large"
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="Email_Address"
                label="Email Address"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter email address",
                  },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder="Enter email address"
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  shape="round"
                  loading={loading}
                >
                  Log in
                </Button>
              </Form.Item>
            </Form>
          </div>

        </div>
      )}
    </>
  );
};

export default AdminLogin;
