import React, { useEffect, useState } from "react";
import config from "../../config";
import axios from "axios";
import {
  Popconfirm,
  Space,
  Table,
  Pagination,
  Button,
  Tooltip,
  message,
} from "antd";
import { NavLink } from "react-router-dom";
import { EditOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
import { render } from "@testing-library/react";

function ManageTanks() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [ListofTanks, SetlistofTanks] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);


  useEffect(() => {
    document.title = "Manage Tank";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetTanks",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          SetlistofTanks(response.data.ListofTanks);
          setLoading(false);
        } else {
          // Handle error
        }
        setLoading(false);
      })
      .catch(function (error) {});
  }, [isDeleted]);

  const columns = [
    {
      title: "ID",
      dataIndex: "ID",
      key: "User ID",
    },
    {
      title: "Tank No",
      dataIndex: "TankNo",
      key: "tankNo",
    },

    {
      title: "Product",
      dataIndex: "ProductID",
      key: "productID",
      render: (_, record) => {
        return record.SelectedProduct.Name;
      },
    },
    {
      title: "Tank Full Capacity",
      dataIndex: "TankFullCapacity",
      key: "balance",
    },

    {
      title: "Useable Capacity",
      dataIndex: "UseableCapacity",
      key: "useableCapacity",
    },
    {
      title: "Tank Shape",
      dataIndex: "TankShape",
      key: "tankShape",
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/dipsettings/edit-tank/${record.ID}`}>
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </NavLink>
          
          <Popconfirm
            title="Sure to delete?"
            discription="Are You sure to delete this Tank"
            onConfirm={(e) => handelDelete(record.ID)}
          >
              <Tooltip title="Delete">
                <DeleteOutlined />
              </Tooltip>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handelDelete = (ID) => {
    setLoading(true);
    setIsDeleted(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };

    const api_config = {
      method: "post",
      url: config.base_url + "Product/DeleteTank",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setLoading(false);
          message.success(response.data.status_message);
        } else {
          setLoading(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
        setLoading(false);
      });
  };
  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const onPageSizeChange = (current, size) => {
    setCurrentPage(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10); // Change this to your desired page size

  // Calculate the start and end index of the data to display
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const ListofTank = ListofTanks.slice(startIndex, endIndex);

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Manage Tanks</h6>
            <div className="header-actions">
              <NavLink to="/dipsettings/add-tank">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                >
                  Add Tank
                </Button>
              </NavLink>
            </div>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={ListofTank}
            pagination={false} // Remove table pagination
            bordered
          />
          <div className="text-end mt-5">
            <Pagination
              current={currentPage}
              pageSize={pageSize}
              size="small"
              total={ListofTank.length}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageTanks;
