import React, { useState, useEffect } from "react";
import Header from "../../../Pdf/PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import PrintFooter from "../../../Pdf/PrintFooter";
import pdfStyles from "../../../Pdf/pdfStyles";
import {PrintFormateNumber} from "../../../Pdf/PrintFormateNumber"
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { calculateColumnTotal } from "../../Shared/Utility";
import { Spin } from "antd";

function PrintCashPayable() {
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const urlParams = new URLSearchParams(window.location.search);
  const EndDate = urlParams.get("EndDate");

  const [listPayable, setListPayable] = useState([]);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: 1,
      PageSize: 20000,
      EndDate: EndDate,
      OrderBy: 1,
    };

    console.log("this is data", data);
    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetPayableReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setListPayable(response.data.listofLedger);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

 
  const generateData = () => {
    if (listPayable.length > 0) {
      return listPayable.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            
            <View style={[pdfStyles.td, { width: 150 }]}>
              <Text style={pdfStyles.tdValue}>{item.Name}</Text>
            </View>

            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{item.Mobile_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.Account_Type_BE?.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 150, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Balance}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Header />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>Cash Payable</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              
              <View style={[pdfStyles.th, { width: 150 }]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>

              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Mobile No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Account Type</Text>
              </View>
              <View style={[pdfStyles.th, { width: 150 }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                  Current Balance
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
            </View>

            {generateData()}

            <View style={pdfStyles.tableHead}>
              
              <View style={[pdfStyles.th, { width: 420 }]}>
                <Text
                  style={[
                    pdfStyles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  Total
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 150 }]}>
                <Text
                  style={[
                    pdfStyles.thValue,
                    { textAlign: "right", fontWeight: "bold" },
                  ]}
                >
                  {PrintFormateNumber(Math.round(calculateColumnTotal(listPayable, "Balance")),0)}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
            </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintCashPayable;
