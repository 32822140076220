import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";

import { Doughnut } from "react-chartjs-2"; 
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Title,
} from "chart.js"; 

import { Select, Skeleton } from "antd";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Title
);

function UsersPieChart(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  // Example data for the pie chart
  useEffect(() => {
    setLoading(true);
    // Mock data for the pie chart
    const fetchData = async () => {
      try {
        // Replace with actual API call
        const data = {
          labels: ["Active Users", "Total Users"],
          datasets: [
            {
              data: [ props.TotalUsers, props.ActiveUsers], // Example values for pie chart
              backgroundColor: ["#FC6E00", "#F0F0F3"],
              hoverBackgroundColor: ["#FC6E00", "#F0F0F3"],
            },
          ],
        };
        setChartData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false);
      }
    };
    
    fetchData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
      title: {
        display: false,
        text: "Sales Distribution by Product",
      },
    },
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Doughnut
            data={chartData} // Use Pie component for pie chart
            options={options}
          />
        </>
      )}
    </>
  );
}

export default UsersPieChart;