import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from "chart.js";

import { Skeleton } from "antd";

ChartJS.register(ArcElement, Tooltip, Legend, Title);

function SubscriptionChart(props) {
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    setLoading(true);

    const retentionRate = 67;

    const remaining = 100 - retentionRate;

    const data = {
      labels: [],
      datasets: [
        {
          data: [retentionRate, remaining],
          backgroundColor: ["#FFE099", "#F0F0F3"],
          hoverBackgroundColor: ["#FFE099", "#F0F0F3"],
          borderWidth: 0,
        },
      ],
    };

    setChartData(data);
    setLoading(false);
  }, []);

  const options = {
    // responsive: true,
    cutout: 0,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            return `${tooltipItem.raw}%`;
          },
        },
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  return (
    <>
      {loading ? (
        <Skeleton active />
      ) : (
        <Doughnut
          data={chartData}
          options={options}
        //   style={{width:'150px',height:'150px'}}
          className="customchartheight"
        />
      )}
    </>
  );
}

export default SubscriptionChart;
