import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import { Spin } from "antd";
import PrintFooter from "../../../Pdf/PrintFooter";
import pdfStyles from "../../../Pdf/pdfStyles";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

const PrintVoucherList = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const voucher_id = urlParams.get("voucher_id");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: voucher_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVoucherByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          SetlistofVouchers(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

 
 

  const generateDataDebitAccount = () => {
    return (
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.td, { width: 100 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.DebitAccount?.Name}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 80 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.DebitAccount?.BalanceType}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 90 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.DebitAccount?.Email_Address}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 90 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.DebitAccount?.Mobile_No}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
          <Text style={pdfStyles.tdValue}>{PrintFormateNumber(listofVouchers.Amount,0)}</Text>
        </View>
        <View style={[pdfStyles.td, { width: 100 }]}>
          <Text style={pdfStyles.tdValue}>
            {PrintFormateNumber(listofVouchers.DebitAccount?.Balance,0)}
          </Text>
        </View>
      </View>
    );
  };

  const generateDataCreditAccount = () => {
    return (
      <View style={pdfStyles.row}>
        <View style={[pdfStyles.td, { width: 100 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.CreditAccount?.Name}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 80 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.CreditAccount?.BalanceType}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 90 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.CreditAccount?.Email_Address}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 90 }]}>
          <Text style={pdfStyles.tdValue}>
            {listofVouchers.CreditAccount?.Mobile_No}
          </Text>
        </View>
        <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
          <Text style={pdfStyles.tdValue}>{PrintFormateNumber(listofVouchers.Amount,0)}</Text>
        </View>
        <View style={[pdfStyles.td, { width: 100 }]}>
          <Text style={pdfStyles.tdValue}>
            {PrintFormateNumber(listofVouchers.CreditAccount?.Balance,0)}
          </Text>
        </View>
      </View>
    );
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>GENERAL VOUCHERS</Text>
          </View>
          <View style={pdfStyles.table}>
            <Text style={{ fontSize: "12pt", padding: "5px" }}>
              Debit Account Details:
            </Text>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Account Title</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Email Address</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Mobile NO</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
              <View
                style={[
                  pdfStyles.th,
                  {
                    width: 100,
                    display: "flex",
                    flexDirection: "column",
                  },
                ]}
              >
                <Text style={pdfStyles.thValue}>Current Balance</Text>
                <Text style={pdfStyles.thValue}>(After this Entry)</Text>
              </View>
            </View>

            {generateDataDebitAccount()}
          </View>

          <View style={[pdfStyles.table, { marginTop: "20px" }]}>
            <Text style={{ fontSize: "12pt", padding: "5px" }}>
              Credit Account Details:
            </Text>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Account Title</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Email Address</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>Mobile NO</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Amount</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>
                  Current Balance (After this Entry)
                </Text>
              </View>
            </View>
            {generateDataCreditAccount()}
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed/>
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintVoucherList;
