import React, { useState, useEffect } from "react";
import { Employee, Payable } from "../UserControls/Icons";
import { NumberFormat } from "../Shared/Utility";
import axios from "axios";
import config from "../../config";

import { Col, Row, Space, Spin, Button, Table, Dropdown, Menu } from "antd";
import { Ledger,Wallet} from "../UserControls/Icons";
import { NavLink } from "react-router-dom";
import {

  DownloadOutlined,
  ShoppingCartOutlined,
  DollarCircleOutlined,
  StockOutlined,
} from "@ant-design/icons";

import {
  Advances,
  CalendarIcon,
  Cash,
  Dollar,
  Dots,
  Expenses,
  FuelTank,
  GraphDown,
  GraphUp,
  Items,
  Nozzel,
  NozzleOil,
  Paper,
  PumpMachine,
  Stock,
  Tank,
  TodayCalendar,
  WeekCalendar,
  YesterdayCalendar,
} from "../UserControls/Icons";
import dayjs from "dayjs";

function VouchersDashboard() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [VoucherData, setVoucherData] = useState([]);
  const [listOfVouchers, setListOfVouchers] = useState([]);

  useEffect(() => {
    document.title = "Vouchers Dashboard";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    console.log("data----", data);

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchersDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log("vouchers list", response.data);
        if (response.data.status_code == 1) {
          setListOfVouchers(response.data.ListofRecentVouchers);
          setVoucherData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, []);

  const Employeecolumns = [
    {
      title: "SR#",
      render: (_, record, index) => index + 1,
      key: "SR",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "Dated",
      render: (_, record) => dayjs(record.Dated).format(config.date_format),
    },
    {
      title: "Debit Account",
      dataIndex: "Debit_Account",
      key: "Debit_Account",
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>
              {record.Debit_Account?.Name.split(" ")
                .map((word) => word.charAt(0))
                .join("")}
            </span>
          </div>
          <div className="content">
            <span className="title">{record.Debit_Account?.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Credit Account",
      dataIndex: "Credit_Account",
      key: "Credit_Account",
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>
              {record.Credit_Account?.Name.split(" ")
                .map((word) => word.charAt(0))
                .join("")}
            </span>
          </div>
          <div className="content">
            <span className="title">{record.Credit_Account?.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: "Voucher Mode",
      dataIndex: "VoucherMode",
      key: "VoucherMode",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="1">
              <NavLink to={`/vouchers/manage-vouchers/`}>View Vouchers</NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink to={`/`}>Edit Vouchers</NavLink>
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button type="link">
              <Dots />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <>
      {/* {contextHolder} */}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            <Space size="large">
              <Spin size="large" />
            </Space>
          </div>
        ) : (
          <div className="page-content bg-gray">
            <div className="page-header">
              <h3 className="page-title">Vouchers</h3>
            </div>

            <Row gutter={[20, 20]} className="mb-5">
              <Col sm={24} md={8}>
                <div className="border-card blue">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <Ledger />
                      {/* <ShoppingCartOutlined className="icon" /> */}
                    </div>
                    <NavLink to={`/vouchers/receipts`}>
                    <span>Today’s Receipts</span></NavLink>
                  </div>
                  <div className="card-content">
                    <p>
                      <span>Rs.</span> {VoucherData.todaysReceipts}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={8}>
                <div className="border-card yellow">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <Payable />
                    </div>
                    <NavLink to={`/vouchers/payment`}>
                    <span>Today’s Payments</span></NavLink>
                    
                  </div>
                  <div className="card-content ">
                    <p>
                      <span>Rs.</span> {VoucherData.todaysPayments}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={8}>
                <div className="border-card purpole">
                  <div className="card-head">
                    <div className="icon-wrap">
                    <Wallet className="icon" />
                    </div>
                    <NavLink to={`/vouchers/expense`}>
                    <span>Today’s Expenses</span></NavLink>
                  </div>
                  <div className="card-content">
                    <p>
                      <span>Rs.</span> {VoucherData.todaysExpenses}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={8}>
                <div className="border-card purpole">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <DollarCircleOutlined className="icon" />
                    </div>
                    <NavLink to={`/vouchers/credit-sale`}>
                    <span>Today’s Credit Sales</span></NavLink>
                  </div>
                  <div className="card-content">
                    <p>
                      <span>Rs.</span> {VoucherData.todaysCreditSales}
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={24} md={8}>
                <div className="border-card yellow">
                  <div className="card-head">
                    <div className="icon-wrap">
                      <ShoppingCartOutlined className="icon" />
                    </div>
                    <NavLink to={`/vouchers/credit-purchase`}>
                    <span>Today’s Credit Purchase</span></NavLink>
                  </div>
                  <div className="card-content ">
                    <p>
                      <span>Rs.</span> {VoucherData.todaysCreditPurchase}
                    </p>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <Employee />
                      </span>
                      <h4>Recent Vouchers Added</h4>
                    </div>
                  </div>
                  <div
                    className="fb-card-body table"
                    style={{ "--cardheight": "350px" }}
                  >
                    <Table
                      columns={Employeecolumns}
                      dataSource={listOfVouchers}
                      pagination={false}
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default VouchersDashboard;
