import axios from 'axios';
import React, { useEffect, useState } from 'react'
import config from '../../config';


function ProductsDropDown(props) {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [ListofProducts, setListofProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
            //ID: props.ID

        }
        var api_config = {
            method: 'post',
            url: config.base_url + 'Product/GetProducts',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(api_config)
            .then(function (response) {
                //console.log('products', response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    setListofProducts(response.data.ListofProducts);
                    //setAccount(response.data)
                    setLoading(false);

                } else {
                    // Handle error

                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });

    }, []);

    if (!loading) {

        
        const result = ListofProducts.map((item) => ({
            value: item.ID,
            label: item.Name,
            price: item.Sale_Price,

        }));
       

        return result;
    }
    
    else {
        return false;
    }
}

export default ProductsDropDown
