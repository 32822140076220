import React, { useEffect, useState } from 'react'
import config from '../../config';
import axios from 'axios';
import { FuelTank, Nozzel } from '../UserControls/Icons';

import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Skeleton } from 'antd';


ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);




const ProductSaleChart = () => {

    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [data, setData] = useState({ ListofProducts: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);
        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
        };

        var api_config = {
            method: "post",
            url: config.base_url + "Sale/GetTopProductSaleGraph",
            headers: {
                "Content-Type": "application/json",
            },
            data: data,
        };


        axios(api_config)
            .then(function (response) {
                console.log('products chart',response.data);
                if (response.data.status_code == 1) {
                    console.log(response.data)
                    setData(response.data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.error("Error fetching Sale Graph:", error);
                setLoading(false);
            });
    }, []);

    const options = {
        responsive: true,
        plugins: { legend: { display: false } },
        interaction: {
            intersect: false,
            mode: 'index',
        },
        scales: {
            x: {
                display: false,
            },
            y: {
                display: false,
            },
        },
    };

    const Card = () => {
        if (data?.ListofProducts.length != null || data?.ListofProducts.length > 0) {
            return data?.ListofProducts.map((item, index) => {
                return (
                    <>
                        <div className="fb-card mb-5">
                            <div className="fb-card-header">
                                <div className="fb-card-title">
                                    <span>
                                        {item.Name == "Hi Speed Diesel" ?
                                            <Nozzel />
                                            :
                                            <FuelTank />
                                        }
                                    </span>
                                    <h4>{item.Name}</h4>
                                </div>

                            </div>
                            <div className="fb-card-body">
                                <div className="fb-card-content">
                                    <div className="content-text">
                                        <span>Ltr</span>
                                        <h3>{item.Balance}</h3>
                                    </div>
                                    <div className="content-icon">
                                        <Line
                                            data={{
                                                labels: item.ListofDates,
                                                datasets: [
                                                    {
                                                        data: item.SalesArray,
                                                        backgroundColor: '#5983B2',
                                                        borderColor: '#5983B2',
                                                        borderWidth: 1,
                                                        barPercentage: 0.25,
                                                        borderRadius: 50,
                                                    },
                                                ],
                                            }}
                                            options={options}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                );
            });
        }
    };

    return (
        <>
            {
                loading ?
                    <Skeleton active />
                    :
                    Card()
            }
        </>
    )
}

export default ProductSaleChart