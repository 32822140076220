import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import {
  Button,
  Card,
  Col,
  Select,
  Form,
  Input,
  Row,
  message,
  Spin,
} from "antd";
import { NavLink, useParams,useNavigate } from "react-router-dom";

function EditProduct(props) {

  const navigate = useNavigate();

  const goBack=()=>{
    navigate(-1);
  }
  const [form] = Form.useForm();
  const [Product, SetProduct] = useState({});
  const [update, setUpdate] = useState(false);

  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  let params = useParams();

  const { Option } = Select;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loadingGetProduct, setLoadingGetProduct] = useState(false);

  useEffect(() => {
    document.title = "Edit Product";
    setLoadingGetProduct(true);
    setUpdate(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };


    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        
        if (response.data.status_code == 1) {
          setLoadingGetProduct(false);
          SetProduct(response.data.Product);
        } else {
          setLoadingGetProduct(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [update]);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      ...formData,
    };



    var api_config = {
      method: "post",
      url: config.base_url + "/Product/UpdateProduct",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    

    axios(api_config)
      .then(function (response) {
  
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink onClick={goBack}>
                <ChevronLeftIcon />
              </NavLink>
              Edit Product
            </h6>
          </div>

          <Row gutter={[24, 24]}>
            <Col md={12} xs={24} offset={{ md: 6 }}>
              <Card title="Edit product" loading={loadingGetProduct}>
                <Form
                  name="basic"
                  layout="horizontal"
                  form={form}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  onFinish={handleSubmit}
                  autoComplete="off"
                  fields={[
                    {
                      name: "ProductCode",
                      value: Product.ProductCode,
                    },
                    {
                      name: "MeasureUnitID",
                      value: Product.MeasureUnitID,
                    },
                    {
                      name: "Sale_Price",
                      value: Product.Sale_Price,
                    },
                    {
                      name: "Name",
                      value: Product.Name,
                    },
                    {
                      name: "Last_Purchase_Price",
                      value: Product.Last_Purchase_Price,
                    },

                    {
                      name: "Description",
                      value: Product.Description,
                    },
                  ]}
                >
                  <Form.Item
                    label="Product Code"
                    name="ProductCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your product code"
                      disabled={Product.Is_Default}
                    />
                  </Form.Item>

                  <Form.Item
                    name="MeasureUnitID"
                    label="Measure Unit"
                    rules={[
                      {
                        message: "Please enter the measure unit",
                        required: true,
                      },
                    ]}
                  >
                    <Select placeholder="Select a measure unit" allowClear>
                      <Option value={1}>Litre</Option>
                      <Option value={2}>Gallon</Option>
                      <Option value={3}>Piece</Option>
                      <Option value={4}>Pound</Option>
                      <Option value={5}>KG</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Product Name"
                    name="Name"
                      
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your product name" disabled={Product.Is_Default}/>
                  </Form.Item>
                  <Form.Item
                    label="Sale Price"
                    name="Sale_Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input sales price",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your sale price" />
                  </Form.Item>
                  <Form.Item
                    label="Last Purchase"
                    name="Last_Purchase_Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input last price",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your last purchase" />
                  </Form.Item>
                  <Form.Item label="Description" name="Description">
                    <Input placeholder="Enter your description" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Updated"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditProduct;
