import React, { useEffect, useState } from "react";
import config from "../../../config";
import pdfStyles from "../../../Pdf/pdfStyles";
import axios from "axios";
import moment from "moment";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import { Spin } from "antd";

import PrintHeader from "../../../Pdf/PrintHeaderAccount";
import PrintFooter from "../../../Pdf/PrintFooter";

const PrintMachineReading = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  //   const StartDate = urlParams.get("StartDate");
  //   const EndDate = urlParams.get("EndDate");
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const [loading, setLoading] = useState(false);
  const machine_id = urlParams.get("machine_id");
  const [readingList, setReadingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [PumpMachineNo, setPumpMachineNo] = useState("");

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      PageNo: currentPage,
      PageSize: 5000,
      ID: machine_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "/Product/GetReadings",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          setReadingList(response.data.listofLedger);
          setPumpMachineNo(response.data.listofLedger?.[0]?.Pump_No);
          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const generateData = () => {
    if (readingList.length > 0) {
      return readingList.map((item, index) => {
        return (
          <View key={index} style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>

            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>
                {moment(item.Dated).format(config.date_format)}
              </Text>
            </View>

            <View style={[pdfStyles.td, { width: 70 }]}>
              <Text style={pdfStyles.tdValue}>{item.Pump_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 80 }]}>
              <Text style={pdfStyles.tdValue}>{item.ProductCode}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 115 }]}>
              <Text style={pdfStyles.tdValue}>{item.Last_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 115 }]}>
              <Text style={pdfStyles.tdValue}>{item.Current_Reading}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.Returned}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>{item.UsedQuantity}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeader />

        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>
              Nozzle Reading ({PumpMachineNo})
            </Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>

              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>Pump No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 80 }]}>
                <Text style={pdfStyles.thValue}>Product</Text>
              </View>

              <View style={[pdfStyles.th, { width: 115 }]}>
                <Text style={pdfStyles.thValue}>Last Reading</Text>
              </View>
              <View style={[pdfStyles.th, { width: 115 }]}>
                <Text style={pdfStyles.thValue}>Current Reading</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={[pdfStyles.thValue]}>Return</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Used Qty</Text>
              </View>
            </View>

            {generateData()}
          </View>
          <View
            style={{ marginBottom: 50, marginTop: 10, top: 0, bottom: 0 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="CashReceivable.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintMachineReading;
