import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  ProfileOutlined,
  PrinterOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import LoginChecked from "../Shared/LoginChecked";
import config from "../../config";
import axios from "axios";
import { Space, Table, Pagination, Row, Col, Button, Tooltip, Form,Input,Checkbox, DatePicker } from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";


function ManageProduct() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [listofProducts, SetlistofProducts] = useState([]);
  // const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));
  const [TotalStockValue, setTotalStockValue] = useState("");
  const [HideZeroBalance, setHideZeroBalance] = useState(false);
  const[HideNegativeBalance,setHideNegativeBalance] = useState(false);
  const [Dated, setDated] = useState(null);
  const[OrderBy,SetOrderBy] = useState('')

  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);
 

  useEffect(() => {
    document.title = "Manage Products";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
      HideZeroBalance: HideZeroBalance,
      OrderBy: OrderBy, 
      HideNegativeBalance:HideNegativeBalance,
    };
    // console.log(data)
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProducts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofProducts(response.data.ListofProducts);
          setTotalStockValue(response.data.TotalStockValue);
          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [Dated, pageNo, pageSize,HideZeroBalance, OrderBy,HideNegativeBalance]);

  const handleTableChange = async (pagination, filters, sorter) => {
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";
  
    switch (sortField) {
      case "Name":
        SetOrderBy(sortOrder === "asc" ? 'Name Asc' : 'Name Dsc');
        break;
      case "ProductCode": 
        SetOrderBy(sortOrder === "asc" ? "Code Asc" : 'Code Dsc'); 
        break;
      case "Sale_Price":
        SetOrderBy(sortOrder === "asc" ? 'Sale Price Asc' : 'Sale Price Dsc');
        break;
      case "Last_Purchase_Price":
        SetOrderBy(sortOrder === "asc" ? 'Purchase Price Asc' : 'Purchase Price Dsc'); 
        break;
      case "Balance":
        SetOrderBy(sortOrder === "asc" ? 'Quantity Asc' : 'Quantity Dsc');
        break;
      case "StockValue":
        SetOrderBy(sortOrder === "asc" ? 'StockValue Asc' : 'StockValue Dsc'); 
        break;
      default:
        
        break;
    }
  
    
  };
  

  const columns = [
    {
      title: "SR",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "ProductCode",
      key: "code",
      sorter: true,
    },
    {
      title: "Sale Price",
      dataIndex: "Sale_Price",
      key: "saleprice",
      align: "right",
      sorter: true,
    },
    {
      title: "Last Purchase Price ",
      dataIndex: "Last_Purchase_Price",
      key: "lppp",
      align: "right",
      sorter: true,
    },
    {
      title: "Current Qty ",
      dataIndex: "Balance",
      key: "qty",
      align: "right",
      sorter: true,
    },
    {
      title: "Balance Type",
      dataIndex: "BalanceType",
      key: "type",
      align: "right",
    },
    {
      title: "Stock Value",
      key: "stock",
      align: "right",
      sorter: (a, b) => {
        const stockValueA = a.Balance * a.Last_Purchase_Price;
        const stockValueB = b.Balance * b.Last_Purchase_Price;
        return stockValueA - stockValueB; 
      },
      render: (_, record) => {
        const stockValue = record.Balance * record.Last_Purchase_Price;
        return stockValue.toFixed(2);
      }
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="account-actions">
        <Space size="middle">
        <div>
          <NavLink to={`/products/edit-product/${record.ID}`}>
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </NavLink>
          </div>
          <NavLink to={`/reports/product-ledger/?product_id=${record.ID}`}>
            <Tooltip title="View Ladger">
              <ProfileOutlined />
            </Tooltip>
          </NavLink>
        </Space>
        </div>
      ),
    },
  ];
  const getFooterContent = () => (
    <Row>
      <Col md={16} xs={16}>
        <p>
          <b>Total Stock Value:</b>
        </p>
      </Col>
      <Col md={6} xs={8} style={{ textAlign: "right" }}>
        <p>{TotalStockValue}</p>
      </Col>
    </Row>
  );
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Manage Products</h3>

            <div className="header-actions">
              <NavLink
                 to={`/products/print-Product-list/?Date=${Dated}&OrderBy=${OrderBy}&HideZeroBalance=${HideZeroBalance}&HideNegativeBalance=${HideNegativeBalance}`}
                target="blank"
              >
                <Button type="dashed" shape="round" size="large">
                  <PrinterOutlined />
                  Download / Print
                </Button>
              </NavLink>
              <NavLink to={`/products/add-new-product`}>
                <Button type="primary" shape="round" size="large">
                  <PlusOutlined style={{ color: "#fff" }} /> Add Product
                </Button>
              </NavLink>
            </div>
            
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
              <DatePicker
                  onChange={(date, dateString) => setDated(dateString)}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              
              <Form.Item>
                <Checkbox 
                onClick={() => setHideZeroBalance((prev) => !prev)}
                >
                  Hide Products With Zero Balance
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Checkbox 
                onClick={() => setHideNegativeBalance((prev) => !prev)}
                >
                  Hide Products With Negative Balance
                </Checkbox>
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={listofProducts}
            footer={getFooterContent}
            pagination={false} // Remove table pagination
            bordered
            onChange={handleTableChange}
            />

          {/* <Row gutter={[16, 20]} style={{ marginTop: '20px' }}>
                        
                    </Row> */}
        </div>
      </div>
    </>
  );
}

export default ManageProduct;
