import React from "react";
import Header from "../Layout/Header";

import SecurityMenu from "./SecurityMenu";
import LoginChecked from "../Shared/LoginChecked";

function AdjustLedger() {
  return (
    <>
      <LoginChecked />
      <div className="sub-menu-wrap">
        <h5>Security</h5>
        <SecurityMenu />
      </div>
      <div className="right-side-contents">
        <Header title="Adjust Ledgers" />
      </div>
    </>
  );
}

export default AdjustLedger;
