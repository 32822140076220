import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import { useParams } from "react-router-dom";
import config from "../../../config";
import Header from "../../../Pdf/PrintHeaderAccount";
import { Spin } from "antd";
import pdfStyles from "../../../Pdf/pdfStyles";
import PrintFooter from "../../../Pdf/PrintFooter";
import { PrintFormateNumber } from "../../../Pdf/PrintFormateNumber";

const PrintVouchersDetail = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const voucher_id = urlParams.get("voucher_id");

  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const StartDate = urlParams.get("StartDate");
  const EndDate = urlParams.get("EndDate");
  const AccountID = urlParams.get("AccountID");
  const VoucherType = urlParams.get("VoucherType");
  const VoucherMode = urlParams.get("VoucherMode");
  const DebitAccountID = urlParams.get("DebitAccountID");
  const creditAccountID = urlParams.get("creditAccountID");

  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [total, SetTotal] = useState("1");
  const [OrderBy, SetOrderBy] = useState("1");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      AccountID: AccountID,
      VoucherType: VoucherType,
      VoucherMode: VoucherMode,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
      PageNo: 1,
      PageSize: 2000,
    };
    // console.log(data)
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchersWithType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log('response------', response.data)
        if (response.data.status_code == 1) {
          SetTotal(response.data);
          SetlistofVouchers(response.data.listofVouchers);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const generateData = () => {
    if (listofVouchers.length > 0) {
      return listofVouchers.map((item, index) => {
        return (
          <React.Fragment key={index} wrap={false}>
            {/* {(index + 1) % 31 === 0 && (
            <View style={pdfStyles.pageBreak} />
          )} */}
            <View key={index} style={pdfStyles.row}>
              <View style={[pdfStyles.td, { width: 30 }]}>
                <Text style={pdfStyles.tdValue}>{index + 1}</Text>
              </View>
              <View style={[pdfStyles.td, { width: 70 }]}>
                <Text style={pdfStyles.tdValue}>
                  {moment(item.Dated).format("DD MMM YYYY")}
                </Text>
              </View>
              <View style={[pdfStyles.td, { width: 50 }]}>
                <Text style={pdfStyles.tdValue}>{item.VoucherNo}</Text>
              </View>

              <View style={[pdfStyles.td, { width: 90 }]}>
                <Text style={pdfStyles.tdValue}>
                  {item.Debit_Account?.Name}
                </Text>
              </View>
              <View style={[pdfStyles.td, { width: 90 }]}>
                <Text style={pdfStyles.tdValue}>
                  {item.Credit_Account?.Name}
                </Text>
              </View>
              <View style={[pdfStyles.td, { width: 160 }]}>
                <Text style={pdfStyles.tdValue}>{item.Description}</Text>
              </View>
              <View style={[pdfStyles.td, { width: 90 }]}>
                <Text style={[pdfStyles.tdValue, { textAlign: "right" }]}>
                  {PrintFormateNumber(item.Amount,0)}
                </Text>
              </View>
            </View>
          </React.Fragment>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <Header />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>
              {VoucherMode == "CreditSale"
                ? "Credit Sale"
                : VoucherMode == "CreditPurchase"
                ? "Credit Purchase"
                : VoucherMode == "Payments"
                ? "Payments"
                : VoucherMode == "Receipts"
                ? "Receipt"
                : VoucherMode == "Expenses"
                ? "Expense"
                : VoucherMode == "General"
                ? "General"
                : ""}
              {""} Voucher Details
            </Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 30 }]}>
                <Text style={pdfStyles.thValue}>SR.</Text>
              </View>
              <View style={[pdfStyles.td, { width: 70 }]}>
                <Text style={pdfStyles.thValue}>Dated</Text>
              </View>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>No</Text>
              </View>

              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>
                  {VoucherMode == "CreditSale"
                    ? "Customer name"
                    : VoucherMode == "CreditPurchase"
                    ? "Customer name"
                    : VoucherMode == "Payments"
                    ? "Paid To"
                    : VoucherMode == "Receipts"
                    ? "Receipt In"
                    : VoucherMode == "Expenses"
                    ? "Expense Account"
                    : VoucherMode == "General"
                    ? "Debit Account"
                    : ""}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={pdfStyles.thValue}>
                  {VoucherMode == "CreditSale"
                    ? "From Account"
                    : VoucherMode == "CreditPurchase"
                    ? "From Account"
                    : VoucherMode == "Payments"
                    ? "From Account"
                    : VoucherMode == "Receipts"
                    ? "From"
                    : VoucherMode == "Expenses"
                    ? "From Account"
                    : VoucherMode == "General"
                    ? "Credit Account"
                    : ""}
                </Text>
              </View>
              <View style={[pdfStyles.th, { width: 160 }]}>
                <Text style={pdfStyles.thValue}>Description</Text>
              </View>
              <View style={[pdfStyles.th, { width: 90 }]}>
                <Text style={[pdfStyles.thValue, { textAlign: "right" }]}>
                  Amount
                </Text>
              </View>
            </View>

            {generateData()}

            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 490, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>

              <View style={[pdfStyles.th, { width: 90,textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(total.Total,0)}</Text>
              </View>
              
            </View>

            
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="sample.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintVouchersDetail;
