export function calculateColumnTotal(data, columnName) {
  let total = 0;

  data.forEach((row) => {
    const columnValue = parseFloat(row[columnName]);
    total += isNaN(columnValue) ? 0 : columnValue;
  });

  return total;
}

// export const RenderHTMLString = (htmlString) => {
//   // Your function logic goes here
//   return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
// };


// export const NumberFormat = (value, point) => {
//   if (value == null || point == null) {
//     return "";
//   }
//   else {
//     const formattedNumber = new Intl.NumberFormat("en-US", {
//       minimumFractionDigits: point,
//       maximumFractionDigits: point,
//     }).format(value);

//     return formattedNumber;
//   }

// };
export const NumberFormat = (value, point) => {
  if (point == null && value != null) {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);

    return formattedNumber;
  }
  if (value == null || point == null) {
    return "";
  }
  else {
    const formattedNumber = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: point,
      maximumFractionDigits: point,
    }).format(value);

    return formattedNumber;
  }

};
export const RenderHTMLStringWithoutDiv = (htmlString) => {
  return (

    <table className="add-render-table">
      <tbody dangerouslySetInnerHTML={{ __html: htmlString }} />
    </table>


  );
};