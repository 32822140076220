import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import AccountDropDown from "../Shared/AccountDropDown";
import moment from "moment";
import { RenderHTMLStringWithoutDiv } from "../Shared/Utility";
import { Button, DatePicker, Form, Select, Spin } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";
function DateWiseProductWiseSale() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [AccountID, SetAccountID] = useState(0);
  const [data, setData] = useState([]);

  const AccountType = AccountDropDown();
  // data = RenderHTMLString(data);
  // console.log("data is this", data);

  useEffect(() => {
    document.title = "Datewise Product Sale";
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      AccountID: AccountID,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDateWiseCustomerSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          setData(response.data.TableDetails);

          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, AccountID]);

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disableDate=(current)=>{
    return current && current>moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Date Wise Product Summary Sheet</h3>

            <div className="header-actions">
              <NavLink to="#" target="_blank">
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker allowClear onChange={handleDateChange} 
                disabledDate={disableDate}/>
              </Form.Item>
              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: "200px",
                  }}
                  placeholder="All Customers"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  onChange={(value) => SetAccountID(value)}
                  options={AccountType}
                />
              </Form.Item>
            </Form>
          </div>

          {
            loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "60vh",
                  width: "100%",
                }}
              >
                <Spin />
              </div>
            ) : (
              <>
                <div class="ant-table ant-table-small ant-table-bordered ant-table-scroll-horizontal">
                  <div class="ant-table-container" >
                    <div className="ant-table-content">
                      <div className="table-style">
                      <table style={{width:'100%',borderSpacing:"0px"}}>

                        <tbody
                          className="ant-table-tbody"
                          dangerouslySetInnerHTML={{ __html: data }}
                        />
                      </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* {RenderHTMLStringWithoutDiv(data)} */}
              </>
            )

            // <div>{RenderHTMLStringWithoutDiv}</div>
            // <div>{(data)}</div>
          }
        </div>
      </div>
    </>
  );
}

export default DateWiseProductWiseSale;
