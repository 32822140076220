import React from 'react'
import { NavLink } from 'react-router-dom'


function DashboardMenu() {
  return (
    <>
    <ul className="sub-menu">
        <li><NavLink to="/dashboard/analytics">Analytics</NavLink> </li>
        <li><NavLink  to="/dashboard/stock">Stocks</NavLink > </li>
        <li><NavLink to="/dashboard/sales">Sales</NavLink> </li>
    </ul>
</>
  )
}

export default DashboardMenu
