import { Row, Col, Button, Menu, Divider, Input } from "antd";
import React, { useState } from "react";
import {
  InboxArrowDownIcon,
  XCircleIcon,
  InboxIcon,
  ChevronLeftIcon,
} from "@heroicons/react/24/outline";
import {
  FileAddOutlined,
  PlusOutlined,
  UploadOutlined,
  FileOutlined,
  PictureOutlined,
  PaperClipOutlined,
  SearchOutlined,
  LinkOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import MenuDivider from "antd/es/menu/MenuDivider";

function SupportChat() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);

  const handelSubmit = (values) => {
    console.log("Submitted values: ", values);
    // You can process the form values here
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">Move to Open Tickets</Menu.Item>
      <Menu.Item key="2">Move to Closed Tickets</Menu.Item>
      <Menu.Item key="3">Delete</Menu.Item>
    </Menu>
  );

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content bg-gray">
          <div className="page-header">
            <h3 className="page-title">Support</h3>

            <div className="header-actions">
              <p>Facing an issue?</p>

              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={showModal}
                icon={<PlusOutlined style={{ color: "white" }} />}
              >
                Create Ticket
              </Button>
            </div>
          </div>

          <Row gutter={24}>
            <Col md={8}>
              <div className="fb-card support-inbox">
                <NavLink className="support-item">
                  <InboxIcon />
                  <span>Inbox</span>
                </NavLink>
                <NavLink to="/open/ticket" className="support-item">
                  <InboxArrowDownIcon />
                  <span>Open Tickets</span>
                </NavLink>
                <NavLink to="/close/ticket" className="support-item">
                  <XCircleIcon />
                  <span>Closed Tickets</span>
                </NavLink>
              </div>
            </Col>
            <Col md={16}>
              <div className="fb-card support-inbox">
                <div className="sp-inbox-header">
                  <ChevronLeftIcon />
                  <div>
                    <p>Billing and Payments - Ticket # 1234</p>
                    <span>
                      I have submitted the payment but my account is not
                      activated yet
                    </span>
                  </div>
                </div>
                <Divider />
                <div className="time-date">
                  <p>Thursday,Jan 4 </p>
                  <p>6:21 PM</p>
                </div>
                <div className="chat">
                  <div className="sp-inbox-header">
                    <div className="avtar">rap</div>
                    <div className="chat-q">
                      <p>
                        Hello, I was charged twice for monthly payment. Can you
                        please help?
                      </p>
                    </div>
                  </div>
                  <div className="chat-ans">
                    <p>
                      Thank you for reaching out. Let me check your account
                      details and get back to you shortly.{" "}
                    </p>
                  </div>
                </div>
                <div className="sp-inbox-footer">
                  <div className="custom-input">
                    <FileOutlined className="file-icon" />
                    <Input placeholder="Enter message" bordered={false} />
                    <LinkOutlined className="link-icon" />
                  </div>
                  <div className="send-input">
                    <SendOutlined />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SupportChat;
