import React, { useEffect, useState } from "react";
import { Button, Row, Col, Table, Dropdown, Skeleton, Menu } from "antd";
import { AccountCard, Advances, Cross, Dots, Employee, Expenses, GraphDown, GraphUp, Ledger, Payable, Wallet } from "../UserControls/Icons";
import axios from "axios";
import config from "../../config";
import { NumberFormat } from "../Shared/Utility";
import { NavLink } from "react-router-dom";

const AccountDashboard = () => {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = "Fuelbook - Accounts Dashboard";

    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Account/GetAccountsDashboard",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };


    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
      
          setData(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        setLoading(false);
      });
  }, []);


  const Overviewcolumns = [
    {
      title: 'Account Type',
      dataIndex: 'AccountType',
      key: 'AccountType',
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Total Accounts',
      dataIndex: 'TotalAccounts',
      key: 'TotalAccounts',
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      render: (_, record) => (NumberFormat(record.Balance, 2)),
    },
  ];

  const Employeecolumns = [
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      render: (_, record) => (NumberFormat(record.Balance, 2)),
    },
    {
      title: 'Balance Type',
      dataIndex: 'BalanceType',
      key: 'BalanceType',
      render: (_, record) => (
        <div className="table-tag-row">
          <div className={`tag ${record.BalanceType == 'Debit' ? 'orange' : 'purpul'}`}>
            <span className="tag-line">{record.BalanceType}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Phone no.',
      dataIndex: 'Phone_No',
      key: 'Phone_No',
    },
    {
      title: 'Email',
      dataIndex: 'Email_Address',
      key: 'Email_Address',
    },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      render: (_, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="1">
              <NavLink
                to={`/accounts/account-ledger/?account_id=${record.ID}`}
              >
                View Ledger
              </NavLink>
            </Menu.Item>
            <Menu.Item key="2">
              <NavLink
                to={`/accounts/edit-account/${record.ID}`}
              >
                Edit Ledger
              </NavLink>
            </Menu.Item>
          </Menu>
        );


        return (
          <Dropdown overlay={menu} placement="bottomLeft" arrow>
            <Button type="link">
              <Dots />
            </Button>
          </Dropdown>
        )
      },
    },
  ];

  const InActiveAccountcolumns = [
    {
      title: 'ID',
      dataIndex: 'ID',
      key: 'ID',
    },
    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },



  ];

  const ZeroBalancecolumns = [

    {
      title: 'Name',
      dataIndex: 'Name',
      key: 'Name',
      render: (_, record) => (
        <div className="table-avatar">
          <div className="icon">
            <span>{record.Name.split(' ').map(word => word.charAt(0)).join('')}</span>
          </div>
          <div className="content">
            <span className="title">{record.Name}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'BalanceType',
      dataIndex: 'BalanceType',
      key: 'BalanceType',
      render: (_, record) => (
        <div className="table-tag-row">
          <div className={`tag ${record.BalanceType == 'Debit' ? 'orange' : 'purpul'}`}>
            <span className="tag-line">{record.BalanceType}</span>
          </div>
        </div>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'Balance',
      key: 'Balance',
      render: (_, record) => (NumberFormat(record.Balance, 2)),
    },
  ];

  return (
    <>

      <div className="right-side-contents">
        <div className="page-content bg-gray">
          <div className="page-header">
            <h3 className="page-title main-dashboard-title">Accounts Dashboard</h3>
          </div>
          {
            loading ?
              <>
                <Skeleton active />
              </>
              :
              <>
                <Row gutter={[20, 20]}>
                  <Col sm={24} md={8}>
                    <div className="border-card blue">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <Payable />
                        </div>
                        <span>Payable Total</span>
                      </div>
                      <div className="card-content">
                        <p><span>Rs.</span> {NumberFormat(data.totalPayable, 2)}</p>
                      </div>
                    </div>

                  </Col>
                  <Col sm={24} md={8}>
                    <div className="border-card yellow">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <Ledger />
                        </div>
                        <span>Receivable Total</span>
                      </div>
                      <div className="card-content">
                        <p><span>Rs.</span> {NumberFormat(data.totalReceiveable, 2)}</p>
                      </div>
                    </div>
                  </Col>
                  <Col sm={24} md={8}>
                    <div className="border-card purpole">
                      <div className="card-head">
                        <div className="icon-wrap">
                          <Wallet className="icon" />
                        </div>
                        <span>Total Expense</span>
                      </div>
                      <div className="card-content">
                        <p><span>Rs.</span> {NumberFormat(data.totalBankBalance, 2)}</p>
                      </div>
                    </div>
                  </Col>

                  <Col md={8}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <Expenses />
                          </span>
                          <h4>Expenses of Current Month</h4>
                        </div>

                      </div>
                      <div className="fb-card-body">
                        <div className="fb-card-content">
                          <div className="content-text">
                            <span>Rs.</span>
                            <h3>{NumberFormat(data.expensesCurrentMonth, 2)}</h3>
                          </div>
                          <div className="content-icon">
                            <GraphUp />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="fb-card mt-4">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <Advances />
                          </span>
                          <h4>Advances</h4>
                        </div>

                      </div>
                      <div className="fb-card-body">
                        <div className="fb-card-content">
                          <div className="content-text">
                            <span>Rs.</span>
                            <h3>{NumberFormat(data.advancesCurrentMonth, 2)}</h3>
                          </div>
                          <div className="content-icon">
                            <GraphDown />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md={16}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <AccountCard />
                          </span>
                          <h4>Overview</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table"
                        style={{ '--cardheight': '330px' }}
                      >
                        <Table columns={Overviewcolumns} dataSource={data.ListofACTypes} pagination={false} />
                      </div>
                    </div>
                  </Col>

                  <Col md={24}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <Employee />
                          </span>
                          <h4>Recent Employee Added</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table"
                        style={{ '--cardheight': '350px' }}
                      >
                        <Table columns={Employeecolumns} dataSource={data.RecentEmployees} pagination={false} />
                      </div>
                    </div>
                  </Col>

                  <Col md={10}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <Cross />
                          </span>
                          <h4>In-active Accounts</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table"
                        style={{ '--cardheight': '300px' }}
                      >
                        <Table columns={InActiveAccountcolumns} dataSource={data.InActiveAccounts} pagination={false} />
                      </div>
                    </div>

                  </Col>
                  <Col md={14}>
                    <div className="fb-card">
                      <div className="fb-card-header">
                        <div className="fb-card-title">
                          <span>
                            <AccountCard />
                          </span>
                          <h4>Accounts with Zero Balance</h4>
                        </div>
                      </div>
                      <div className="fb-card-body table"
                        style={{ '--cardheight': '300px' }}
                      >
                        <Table columns={ZeroBalancecolumns} dataSource={data.AccountWithZeroBalance} pagination={false} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
          }
        </div>
      </div>
    </>
  );
};

export default AccountDashboard;
