import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../config";
import AccountTypesDropdown from "../Shared/AccountTypesDropdown";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../Shared/LoginChecked";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
  Collapse,
  Breadcrumb,
  Table,
  Space, Tooltip,Popconfirm
} from "antd";
import { DeleteOutlined, CreditCardOutlined, EditOutlined, ProfileOutlined } from "@ant-design/icons";
import moment from "moment";
import { NavLink } from "react-router-dom";
import dayjs from "dayjs";

function AddAccount(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const [loading, setLoading] = useState(false);
  const[submitLoading,setSubmitLoading]=useState(false);
  const[ListOfAccounts,setListOfAccounts] = useState([]);

  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();

  const accountTypesArray = AccountTypesDropdown();

  useEffect(() => {
    document.title = "Add Account";
  }, []);

useEffect(()=>{
setLoading(true);
const data={
  UserID:UserID,
  AccessKey:AccessKey,
  NoofRecords:10,
}

var api_config = {
  method: "post",
  url: config.base_url + "Account/GetRecentAccounts",
  headers: {
    "Content-Type": "application/json",
  },
  data: data,
};
axios(api_config)
.then(function (response) {
  console.log(response.data);
  if (response.data.status_code == 1) {
    setListOfAccounts(response.data.listofAccounts)
    
    setLoading(false);
  } else {
   
    setLoading(false);
  }
  //   setLoading(false);
})
.catch(function (error) {
  message.error("Network Error");
  console.log(error);
});

  },[submitLoading])
  const confirm = (e) => {
    // console.log(e);
    message.success("Data deleted successfully");
  };

  const columns = [
    {
      title: "SR",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "Name",
      key: "name",
      sorter: true,
    },
    {
      title: "Created Date",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render:(_,record)=>dayjs(record.Created_Date).format(config.date_format)
      
    },
    {
      title: "Account Type",
      dataIndex: "Account_Type_BE", 
      key: "Account_Type_BE",
      render: (accountTypeBE) => {
        
        return accountTypeBE ? accountTypeBE.Name : "";
      },
    },

    
  ];

  const handleSubmit = (formData) => {
    
    setSubmitLoading(true);
    formData["OpeningDate"] = moment(formData.OpeningDate).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ...formData,
    };

    // console.log("data_account", data);

    var api_config = {
      method: "post",
      url: config.base_url + "account/AddNewAccount",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          
          setSubmitLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setSubmitLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const text = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Opening balance with Debit type for customers will automatically be added
      in the system, However, opening balance for all other balance types and
      accounts will be managed through vouchers.
    </p>
  );
  const text1 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Opening balance with Credit type for customers will be automatically added
      in the system, However, opening balance for all other balance types and
      accounts will be managed through vouchers.
    </p>
  );
  const items = [
    {
      key: "1",
      label: "Opening Balance for Customers",
      children: text,
    },
    {
      key: "2",
      label: "Opening Balance for Suppliers",
      children: text1,
    },
  ];

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <Row>
            <Col span={12}>
              <div className="page-header">
                <h6 className="page-title">
                  <NavLink to="/accounts/manage-accounts">
                    <ChevronLeftIcon />
                  </NavLink>
                  Add Account
                </h6>
              </div>
            </Col>
            <Col span={12}>
              <div className="text-end">
                <NavLink to={`#`}>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    style={{ marginBottom: 10 }}
                  >
                    Import Accounts
                  </Button>
                </NavLink>
              </div>
            </Col>
          </Row>
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <Card>
                <Form
                  name="basic"
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                  //    onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  size="large"
                >
                  <Form.Item
                    label="Account Name"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter account name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Account Name" />
                  </Form.Item>

                  <Form.Item
                    label="Mobile Number"
                    name="Mobile_No"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mobile No",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Mobile Number" />
                  </Form.Item>
                  <Form.Item label="Phone Number" name="Phone_No">
                    <Input placeholder="Enter Phone Number" />
                  </Form.Item>
                  <Form.Item label="Email Address" name="Email_Address">
                    <Input placeholder="Enter Email Address" />
                  </Form.Item>
                  <Form.Item label="Description" name="Description">
                    <Input placeholder="Enter Your Description" />
                  </Form.Item>

                  <Form.Item label="Opening Balance" name="OpeningBalance">
                    <Input placeholder="Enter Opening Balance" />
                  </Form.Item>

                  <Form.Item
                    label="Balance Type"
                    name="OpeningBalanceType"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Select placeholder="Select balance type">
                      <Select.Option value="Debit">Receivable</Select.Option>
                      <Select.Option value="Credit">Payable</Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Account Type"
                    name="Account_Type_ID"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select account type"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={accountTypesArray === false ? true : false}
                      options={accountTypesArray}
                    />
                  </Form.Item>

                  <Form.Item label="Opening Date" name="OpeningDate">
                    <DatePicker />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Add Account"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col xs={24} md={12}>
              <Collapse
                items={items}
                bordered={true}
                defaultActiveKey={["1"]}
              />
              <div style={{marginTop:'20px'}}>
              <Table
              size="small"
              scroll={{
                x: "100%",
              }}
              loading={loading}
              columns={columns}
              dataSource={ListOfAccounts}
              pagination={false}
              // onChange={handleTableChange}
              bordered
              />
              </div>
              {/* <div className="row">
                                <div className="col-md-6">
                                    <div className="mb-5">
                                        <h5>Opening Balance for Customers</h5>
                                        <p className="text-muted">Opening balance with Debit type for customers will automatically be added in the system, However, opening balance for all other balance types and accounts will be managed through vouchers. </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div>
                                        <h5>Opening Balance for Suppliers</h5>
                                        <p className="text-muted">Opening balance with Credit type for customers will be automatically added in the system, However, opening balance for all other balance types and accounts will be managed through vouchers. </p>
                                    </div>
                                </div>
                            </div> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddAccount;
