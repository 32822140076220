import React, { useEffect, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  ProfileOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
// import AccountsMenu from './AccountsMenu'
import axios from "axios";
import config from "../../config";
import dayjs from "dayjs";
import { calculateColumnTotal,NumberFormat } from "../Shared/Utility";
import LoginChecked from "../Shared/LoginChecked";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import { NavLink } from "react-router-dom";
import moment from "moment";

function CashPayable(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [ListOfRecords, seListOfRecords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [EndDate, SetEndDate] = useState(dayjs());
  const[CashPayableTotal,setCashPayableTotal]=useState([]);

  useEffect(() => {
    document.title = "Cash Payable";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: currentPage,
      PageSize: pageSize,
      EndDate: EndDate,
      OrderBy: 1,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetPayableReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          seListOfRecords(response.data.listofLedger);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate]);

  useEffect(() => {
   
    const totalsRow = {
      Sr: null,
      Name: "",
      Email_Address: "",
      Mobile_No: null,
      ACType: "Total",
      Balance: calculateColumnTotal(ListOfRecords,"Balance"),
      action: "",
    };

  let newlist = [...ListOfRecords, totalsRow];
  setCashPayableTotal(newlist);


}, [ListOfRecords]);

  const columns = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
      render:(_,record, index)=>
        record.ID !=null ? (index + 1) : null
    },

    {
      title: "Name",
      dataIndex: "Name",
      key: "Name",
      //   ...getColumnSearchProps('Name'),
      sorter: (a, b) => a.Name.localeCompare(b.Name),
      sortDirection: ["ascend", "descend"],
    },
    {
      title: "Email Address",
      dataIndex: "Email_Address",
      key: "email",
    },
    {
      title: "Mobile No	 ",
      dataIndex: "Mobile_No",
      key: "MobileNo",
    },
    {
      title: "A/C Type",
      dataIndex: "A/CType",
      key: "A/CType",
      render:(_,record)=>
        record.ID != null ? (record.Account_Type_BE?.Name) : "Total"
    },
    {
      title: "	Current Balance	",
      dataIndex: "Balance",
      key: "	Balance	",
      align: "right",
      // render:(_,record)=>NumberFormat(record.Balance,0),
      render:(_,record)=>
        record.ID !=null ? (NumberFormat(record.Balance,0)):(<strong>{NumberFormat(record.Balance,0)}</strong>),
      sorter: (a, b) => String(a.Balance).localeCompare(String(b.Balance)),
      sortDirection: ["ascend", "descend"],
    },
    {
      title: "Type",
      dataIndex: "BalanceType",
      key: "type",
      filters: [
        {
          text: "Credit",
          value: "Credit",
        },
        {
          text: "Debit",
          value: "Debit",
        },
      ],
      onFilter: (value, record) => record.BalanceType === value,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/accounts/edit-account/${record.ID}`} target="_blank">
            <Tooltip title="Edit">
              <EditOutlined />
            </Tooltip>
          </NavLink>

          <NavLink
            to={`/accounts/account-ledger/?account_id=${record.ID}&Name=${record.Name}`}
            target="_blank"
          >
            <Tooltip title="View File">
              <ProfileOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    },
  ];

  const getFooterContent = () => (
    <Row gutter={[16, 16]}>
      {/* First Column */}
      <Col span={14}>
        <Row>
          <Col span={14}>
            <p>
              <b></b>
            </p>
          </Col>
          <Col span={6}>
            <p></p>
          </Col>
        </Row>
      </Col>

      <Col span={6}>
        <Row>
          <Col span={17}>
            <p>
              <b>Total:</b>
            </p>
          </Col>
          <Col span={6}>
            <p>
              <b>
                {NumberFormat(Math.round(calculateColumnTotal(ListOfRecords, "Balance")),0)}
              </b>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Cash Payable</h3>
            <div className="header-actions">
              <NavLink
                to={`/report-cashpayable/print-list/?EndDate=${EndDate}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <DatePicker
                  onChange={(date, dateString) => SetEndDate(dateString)}
                  disabledDate={disabledDate}
                />
              </Form.Item>

              <Form.Item>
                <Select
                  showSearch
                  style={{
                    width: 150,
                    marginLeft: 5,
                  }}
                  placeholder="Order By"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  options={[
                    {
                      value: "1",
                      label: "Name[A-Z]",
                    },
                    {
                      value: "2",
                      label: "Name[Z-A]",
                    },
                    {
                      value: "3",
                      label: "Amount[L-H]",
                    },
                    {
                      value: "4",
                      label: "Amount[H-L]",
                    },
                  ]}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            loading={loading}
            columns={columns}
            dataSource={CashPayableTotal}
            // dataSource={ListOfRecords}
            // onChange={handleTableChange}

            // footer={getFooterContent}
            pagination={false}
            bordered
          />
        </div>
      </div>
    </>
  );
}

export default CashPayable;
