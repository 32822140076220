import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import config from "../../config";
import AccountDropDown from "../Shared/AccountDropDown";
import ProductsDropDown from "../Shared/ProductsDropDown";
import moment from "moment";
import BoxCard from "../Shared/BoxCard";
import { NumberFormat } from "../Shared/Utility";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Input,
  DatePicker,
  Form,
  Select,
  Checkbox,
  Card,
} from "antd";
import { NavLink } from "react-router-dom";
import Header from "../Layout/Header";
import ReportsMenu from "./ReportsMenu";
import { PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { render } from "@testing-library/react";
import LoginChecked from "../Shared/LoginChecked";
import { Stock } from "../UserControls/Icons";
import { ArrowTrendingDownIcon, ArrowTrendingUpIcon } from "@heroicons/react/24/outline";
function ProfitOnSales() {
  const { RangePicker } = DatePicker;
  const [loading, setLoading] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [orderby, SetOrderBy] = useState(1);
  const [EndDate, SetEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [StartDate, SetStartDate] = useState(
    dayjs().subtract(1, "month").format("YYYY-MM-DD")
  );
  const [ProductID, SetProductID] = useState(0);
  const [SupplierID, SetSupplierID] = useState(0);
  const [ShowDetails, setShowDetails] = useState(true);
  const [ListOfRecords, setListOfRecords] = useState([]);
  const [AccountID, SetAccountID] = useState("");
  const [ProfitOnSale, setProfitOnSale] = useState([]);
  const [ListOfSaleProduct, setListOfSaleProduct] = useState([]);
  const accounts = AccountDropDown();
  const Products = ProductsDropDown();

  useEffect(() => {
    document.title = "Profit on Sales Report";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ProductID: ProductID,
      SupplierID: SupplierID,
      ShowDetails: ShowDetails,
    };

    console.log(data)

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetProfitOnSalesReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code === 1) {
          setListOfRecords(response.data.ListofProducts);
          setListOfSaleProduct(response.data.ListofSaleProducts);
          setProfitOnSale(response.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate, StartDate, ProductID, AccountID, ShowDetails]);

  const columns = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Product",
      dataIndex: "Name",
      key: "Product",
    },
    {
      title: "	Quantity",
      dataIndex: "Balance",
      key: "	Quantity",
      render: (text) => {
        return NumberFormat(text);
      },
    },
    {
      title: "Sale Price",
      dataIndex: "Sale_Price",
      key: "SalePrice",
      align: "right",
      render: (text) => {
        return NumberFormat(text);
      },
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render: (text) => {
        return NumberFormat(Math.round(text), 0);
      },
    },
    {
      title: "Buying Price	",
      dataIndex: "Last_Purchase_Price",
      key: "BuyingPrice	",
      align: "right",
      render: (text) => {
        return NumberFormat(text);
      },
    },
    {
      title: "Profit",
      dataIndex: "Profit",
      key: "Profit",
      align: "right",
      render: (text) => {
        return NumberFormat(text);
      },
    },
  ];

  const columnsProductsInfo = [
    {
      title: "Sr. #",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Dated",
      dataIndex: "Created_Date",
      key: "Dated",
      render: (text, record) => {
        const formattedDate = moment(record.Sale_Invoice_Head_BE?.Dated).format("DD MMM YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Product",
      dataIndex: "Name",
      key: "Product",
      render: (_, record) => record.Product_BE?.Name,
    },
    {
      title: "	Quantity",
      dataIndex: "Quantity",
      key: "	Quantity",
      render: (text) => {
        return NumberFormat(text, 0);
      },
    },
    {
      title: "Sale Price",
      dataIndex: "Price",
      key: "SalePrice",
      align: "right",
      render: (text) => {
        return NumberFormat(text);
      },
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
      render: (text) => {
        return NumberFormat(Math.round(text), 0);
      },
    },
    {
      title: "Buying Price	",
      dataIndex: "Purchase_Price",
      key: "BuyingPrice	",
      align: "right",
      render: (text) => {
        return NumberFormat(text, 2);
      },
    },
    {
      title: "Profit",
      dataIndex: "Profit",
      key: "Profit",
      align: "right",
      render: (text) => {
        return NumberFormat(text, 0);
      },
    },
  ];

  const handleAccountChange = (value) => {
    SetAccountID(value);
  };
  const handleProductChange = (value) => {
    SetProductID(value);
  };

  const onDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Profit On Sales</h3>

            <div className="header-actions">
              <NavLink
                to={`/report-profitonsale/print-list/?StartDate=${StartDate}&EndDate=${EndDate}&ProductID=${ProductID}&SupplierID=${SupplierID}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  // style={{ marginBottom: 10 }}
                >
                  <PrinterOutlined />
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item>
                <RangePicker
                  onChange={onDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Products"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={Products ? false : true}
                  options={Products}
                  onChange={handleProductChange}
                />
              </Form.Item>
              <Form.Item>
                <Select
                  allowClear
                  showSearch
                  style={{
                    width: "160px",
                  }}
                  placeholder="All Customers"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  loading={accounts ? false : true}
                  options={accounts}
                  onChange={handleAccountChange}
                />
              </Form.Item>
              <Form.Item>
                <Checkbox checked={ShowDetails} onClick={() => setShowDetails(!ShowDetails)}>
                  Hide Details
                </Checkbox>
              </Form.Item>
            </Form>
          </div>
          <div style={{ marginBottom: "50px" }}>
            <Table
              size="small"
              scroll={{
                x: "100%",
              }}
              loading={loading}
              columns={ShowDetails == true ? columns : columnsProductsInfo}
              dataSource={
                ShowDetails == true ? ListOfRecords : ListOfSaleProduct
              }
              // footer={getFooterContent}
              // onChange={handleTableChange}
              pagination={false}
              bordered
            />
          </div>
          <div className="custom-table">
            <Row gutter={[20,20]}>
              <Col md={12}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <ArrowTrendingUpIcon />
                      </span>
                      <h4>Profit</h4>
                    </div>
                  </div>
                  <div className="fb-card-body table" style={{ '--cardheight': '200px' }}>
                    <ul className="profit-list">
                      <li >
                        <h4>Profit on Sales:</h4>
                        <p>{NumberFormat(ProfitOnSale.GrossProfit, 0)}</p>
                      </li>
                      <li >
                        <h4>Stock Gains:</h4>
                        <p>{NumberFormat(ProfitOnSale.totalGain, 0)}</p>
                      </li>
                      <li >
                        <h4>Other Income</h4>
                        <p>{NumberFormat(0)}</p>
                      </li>
                      <li >
                        <h4 style={{ color: "rgb(85 162 107)" }}>Gross Profit</h4>
                        <p className="profit">{NumberFormat(ProfitOnSale.GrossProfit, 0)}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        <ArrowTrendingDownIcon />
                        
                      </span>
                      <h4>Loss</h4>
                    </div>
                  </div>

                  <div className="fb-card-body table " style={{ '--cardheight': '200px' }}>
                    <ul className="profit-list">
                      <li >
                        <h4>Total Expenses:</h4>
                        <p>{NumberFormat(ProfitOnSale.TotalExpenes, 0)}</p>
                      </li>
                      <li >
                        <h4>Total Discounts:</h4>
                        <p>{NumberFormat(ProfitOnSale.TotalDiscount, 0)}</p>
                      </li>
                      <li >
                        <h4>Total Withdrawal</h4>
                        <p>{NumberFormat(0)}</p>
                      </li>
                      <li >
                        <h4>Stock Losses</h4>
                        <p >{NumberFormat(ProfitOnSale.totalLoss, 0)}</p>
                      </li>
                      <li >
                        <h4 style={{ color: "#ec0d0d" }}>Gross Expenses</h4>
                        <p className="loss">{NumberFormat(ProfitOnSale.GrossExpenses, 0)}</p>
                      </li>
                      
                    </ul>
                  </div>
                </div>
              </Col>
              <Col md={12}>
              <div className="fb-card">
                  <div className="fb-card-header">
                    <div className="fb-card-title">
                      <span>
                        {ProfitOnSale.GrossProfit>ProfitOnSale.GrossExpenses? <ArrowTrendingUpIcon />:<ArrowTrendingDownIcon />}
                      </span>
                      <h4>Net Profit</h4>
                    </div>
                  </div>

                  <div className="fb-card-body table">
                    <ul className="profit-list">
                    <li >
                        <h4 style={{ color: "rgb(85 162 107)" }}>Gross Profit</h4>
                        <p className="profit">{NumberFormat(ProfitOnSale.GrossProfit, 0)}</p>
                      </li>
                      <li >
                        <h4 style={{ color: "#ec0d0d" }}>Gross Expenses</h4>
                        <p className="loss">{NumberFormat(ProfitOnSale.GrossExpenses, 0)}</p>
                      </li>
                      <li >
                        <h4 style={{color:'#405289'}}>Net Profit:</h4>
                        <p className="netprofit">
                          {NumberFormat(
                            ProfitOnSale.GrossProfit -
                              ProfitOnSale.GrossExpenses,
                            0
                          )}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfitOnSales;
