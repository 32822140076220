import React, { useEffect, useState } from "react";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import axios from "axios";
import config from "../../../config";
import PrintHeaderAccount from "../../../Pdf/PrintHeaderAccount";
import { Spin } from "antd";
import pdfStyles from "../../../Pdf/pdfStyles";
import PrintFooter from "../../../Pdf/PrintFooter";
import {PrintFormateNumber} from "../../../Pdf/PrintFormateNumber"

function PrintAccountList() {
  const urlParams = new URLSearchParams(window.location.search);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const orderby = urlParams.get("OrderBy");
  const currentPage = urlParams.get("PageNo");
  const pageSize = urlParams.get("PageSize");
  const BalanceType = urlParams.get("BalanceType");
  const AccountTypeID = urlParams.get("Account_Type_ID");
  const HideZeroBalance = urlParams.get("HideZeroBalance");
  const Dated = urlParams.get("Dated");
  const searchedName = urlParams.get("Name");
  const [data, setData] = useState([]);

  const [listofAccounts, SetlistofAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const[creditAccount,setCreditAccount] = useState([]);
  const[debitAccount,setDebitAccount] = useState([]);

  useEffect(() => {
    setLoading(true);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: orderby,
      PageNo: 1,
      PageSize: 5000,
      BalanceType: BalanceType,
      Account_Type_ID: AccountTypeID,
      Dated: Dated,
      Name: searchedName,
      HideZeroBalance:HideZeroBalance,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountsForPrint",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofAccounts(response.data.listofAccounts);
          setData(response.data);
          setCreditAccount(response.data.listofCreditTypes);
          setDebitAccount(response.data.listofDebitTypes);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  // const formatNumber = (num) => {
  //   if (!num) return "0";
  //   return num.toString().replace(/\B(?=(\d{2})+(?!\d))/g, ",");
  // };

  const generateData = () => {
    if (listofAccounts.length > 0) {
      return listofAccounts.map((item, index) => {
        return (
          <View style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>

            <View style={[pdfStyles.td, { width: 150 }]}>
              <Text style={pdfStyles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>{item.Mobile_No}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.Email_Address}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 100 }]}>
              <Text style={pdfStyles.tdValue}>
                {item.Account_Type_BE?.Name}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 100, textAlign: "right" }]}>
              <Text style={pdfStyles.tdValue}>
                {PrintFormateNumber(item.Balance)}
              </Text>
            </View>
            <View style={[pdfStyles.td, { width: 60 }]}>
              <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };
  const generateDataOfCredit = () => {
    if (creditAccount.length > 0) {
      return creditAccount.map((item, index) => {
        return (
          <View style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 200 }]}>
              <Text style={pdfStyles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 200, textAlign:'right' }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Balance,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };
  const generateDataOfDebit = () => {
    if (debitAccount.length > 0) {
      return debitAccount.map((item, index) => {
        return (
          <View style={pdfStyles.row}>
            <View style={[pdfStyles.td, { width: 50 }]}>
              <Text style={pdfStyles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 200 }]}>
              <Text style={pdfStyles.tdValue}>{item.Name}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 200, textAlign:'right' }]}>
              <Text style={pdfStyles.tdValue}>{PrintFormateNumber(item.Balance,0)}</Text>
            </View>
            <View style={[pdfStyles.td, { width: 120 }]}>
              <Text style={pdfStyles.tdValue}>{item.BalanceType}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={pdfStyles.page}>
        <PrintHeaderAccount />
        <View style={pdfStyles.section}>
          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>List of Accounts</Text>
          </View>
          <View style={pdfStyles.table}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Sr#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 150 }]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>Mobile No</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Email</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100 }]}>
                <Text style={pdfStyles.thValue}>A/C Type</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Current Balance</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
            </View>
            {generateData()}
            <View style={[pdfStyles.tableHead, { fontSize: "10pt" }]}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.DebitSum)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60, textAlign: "left" }]}>
                <Text style={pdfStyles.thValue}>Debit</Text>
              </View>
            </View>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 520, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 100, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.CreditSum)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 60, textAlign: "left" }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
            </View>
          </View>

          <View style={pdfStyles.title}>
            <Text style={pdfStyles.pagetitle}>Accounts Summary</Text>
          </View>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%' }} >
          <View style={[pdfStyles.table,{width:260}]}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Sr#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200 }]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>Balance</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
              
            </View>
            {generateDataOfCredit()}
           
            <View style={[pdfStyles.tableHead, { fontSize: "10pt" }]}>
              <View style={[pdfStyles.th, { width: 250, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.creditTypeTotal,0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120, textAlign: "left" }]}>
                <Text style={pdfStyles.thValue}>Credit</Text>
              </View>
            </View>
          </View>
          <View style={[pdfStyles.table,{width:260}]}>
            <View style={pdfStyles.tableHead}>
              <View style={[pdfStyles.th, { width: 50 }]}>
                <Text style={pdfStyles.thValue}>Sr#</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200 }]}>
                <Text style={pdfStyles.thValue}>Name</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200, textAlign:'right' }]}>
                <Text style={pdfStyles.thValue}>Balance</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120 }]}>
                <Text style={pdfStyles.thValue}>Type</Text>
              </View>
            </View>
            {generateDataOfDebit()}
           
            <View style={[pdfStyles.tableHead, { fontSize: "10pt" }]}>
              <View style={[pdfStyles.th, { width: 250, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>Total</Text>
              </View>
              <View style={[pdfStyles.th, { width: 200, textAlign: "right" }]}>
                <Text style={pdfStyles.thValue}>{PrintFormateNumber(data.debitTypeTotal,0)}</Text>
              </View>
              <View style={[pdfStyles.th, { width: 120, textAlign: "left" }]}>
                <Text style={pdfStyles.thValue}>Debit</Text>
              </View>
            </View>
          </View>
          </View>
          <View
            style={{ marginBottom: 60, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="accounts.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
}

export default PrintAccountList;
