import {
  Row,
  Col,
  Modal,
  Upload,
  Input,
  Select,
  Button,
  Card,
  Menu,
  Dropdown,
  message,
} from "antd";
import React, { useState } from "react";
import {
  InboxArrowDownIcon,
  XCircleIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import { Dots } from "../UserControls/Icons";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { Form } from "antd"; // Using Ant Design Form
import { NavLink } from "react-router-dom";
import axios from "axios";
import config from "../../config";

function SupportInbox() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const showModal = () => setIsModalOpen(true);
  const handleOk = () => setIsModalOpen(false);
  const handleCancel = () => setIsModalOpen(false);
  const [form] = Form.useForm();

  const handelSubmit = (values) => {
    console.log("Submitted values: ", values);
    // You can process the form values here
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">Move to Open Tickets</Menu.Item>
      <Menu.Item key="2">Move to Closed Tickets</Menu.Item>
      <Menu.Item key="3">Delete</Menu.Item>
    </Menu>
  );
  const handleSubmit = (formData) => {
    setLoading(true);
    setIsModalOpen(false);
    form.resetFields();
    formData["FileAttached"] = "";
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...formData,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Support/AddSupportTicket",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log(data);
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);

          setLoading(false);
          //
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="right-side-contents">
        <div className="page-content bg-gray">
          <div className="page-header">
            <h3 className="page-title">Support</h3>

            <div className="header-actions">
              <p>Facing an issue?</p>

              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={showModal}
                icon={<PlusOutlined style={{ color: "white" }} />}
              >
                Create Ticket
              </Button>
            </div>
          </div>

          <Row gutter={24}>
            <Col md={8}>
              <div className="fb-card support-inbox">
                <NavLink className="support-item ">
                  <InboxIcon />
                  <span>Inbox</span>
                </NavLink>
                <NavLink to="/open/ticket" className="support-item ">
                  <InboxArrowDownIcon />
                  <span>Open Tickets</span>
                </NavLink>
                <NavLink to="/close/ticket" className="support-item">
                  <XCircleIcon />
                  <span>Closed Tickets</span>
                </NavLink>
              </div>
            </Col>
            <Col md={16}>
              <div className="fb-card support-inbox">
                <div className="fb-card-header">
                  <h3>Inbox</h3>
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button>
                      <Dots />
                    </Button>
                  </Dropdown>
                </div>
                <div className="tab-list">
                  <ul>
                    <li className="active">
                      <span>Billing and Payment</span>
                      <ChevronRightIcon />
                    </li>
                    <li>
                      <span>Technical Support</span>
                      <ChevronRightIcon />
                    </li>
                    <li>
                      <span>Account Management</span>
                      <ChevronRightIcon />
                    </li>
                    <li>
                      <span>Sales and Subscriptions</span>
                      <ChevronRightIcon />
                    </li>
                    <li>
                      <span>Product Support</span>
                      <ChevronRightIcon />
                    </li>
                    <li>
                      <span>Compliance and Security</span>
                      <ChevronRightIcon />
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            title="Create Ticket"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
          >
            <Row>
              <Col md={24} sm={24}>
                <Form layout="vertical" onFinish={handleSubmit} form={form}>
                  <Form.Item
                    label="Subject"
                    name="Subject"
                    rules={[
                      { required: true, message: "Please enter Subject" },
                    ]}
                  >
                    <Input placeholder="Subject" />
                  </Form.Item>

                  <Form.Item
                    label="Ticket Type"
                    name="TicketType"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      placeholder="Select TicketType"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      options={[
                        {
                          value: "Billing & Payments",
                          label: "Billing & Payments",
                        },
                        {
                          value: "Technical Support",
                          label: "Technical Support",
                        },
                        {
                          value: "Account Management",
                          label: "Account Management",
                        },
                        {
                          value: "Sales & Subscriptions",
                          label: "Sales & Subscriptions",
                        },
                        {
                          value: "Product Support",
                          label: "Product Support",
                        },
                        {
                          value: "Training & Onboarding",
                          label: "Training & Onboarding",
                        },
                        {
                          value: "Feedback & Suggestions",
                          label: "Feedback & Suggestions",
                        },
                        {
                          value: "Compliance & Security",
                          label: "Compliance & Security",
                        },
                        {
                          value: "Partnerships & Integrations",
                          label: "Partnerships & Integrations",
                        },
                      ]}
                    />
                  </Form.Item>

                  <Form.Item label="Description" name="Description">
                    <Input.TextArea
                      placeholder="Description"
                      style={{ height: "150px" }}
                    />
                  </Form.Item>

                  <Form.Item label="FileAttached" name="FileAttached">
                    <Upload>
                      <Button icon={<UploadOutlined />}>Upload File</Button>
                    </Upload>
                  </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SupportInbox;
