import React, { useEffect, useState } from 'react'
import DashboardMenu from './DashboardMenu'
import Header from '../Layout/Header'
import axios from 'axios';
import config from '../../config';
import { Breadcrumb, Card, Col, Row,Table, Space, Spin, Statistic } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { DollarOutlined } from '@ant-design/icons';





function Sales() {
    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [loading, setLoading] = useState(false)
    const [PetrolSaleQuantity, SetPetrolSaleQuantity] = useState("")
    const [DieselSaleQuantity, SetDieselSaleQuantity] = useState("")
    const [CashInHand, SetCashInHand] = useState("")

    const [MonthlyTotalSales, SetMonthlyTotalSales] = useState("")

    useEffect(() => {
        setLoading(true)

        const data = {
            UserID: UserID,
            AccessKey: AccessKey,
        }

        var api_config = {
            method: 'post',
            url: config.base_url + 'Members/GetDashboardData',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        //console.log(api_config);

        axios(api_config)
            .then(function (response) {
                console.log(response.data);
                if (response.data.status_code == 1) {
                    // Handle success
                    SetPetrolSaleQuantity(response.data.PetrolSaleQuantity)
                    SetDieselSaleQuantity(response.data.DieselSaleQuantity)
                    SetCashInHand(response.data.CashInHand)
                    SetMonthlyTotalSales(response.data.TotalSales)
                    setLoading(false)
                } else {
                    // Handle error
                    setLoading(false)
                }
                //   setLoading(false);
            })
            .catch(function (error) {

                console.log(error);
            });
    }, []);

    
    return (
        <>
            <div className="sub-menu-wrap">
                <h5>Dashboard</h5>
                <DashboardMenu />
            </div>
            <div className="right-side-contents">
                <Header title="Sales" />
                <div className='page-content'>
                    <Breadcrumb
                        items={[
                            {
                                title: <NavLink to="/dashboard/analytics">Dashboard</NavLink>,
                            },
                            {
                                title: 'Sales',
                            },
                        ]}
                    />
                    {loading ? <div className='spin-center'> <Space size="large">  <Spin size="large" /> </Space> </div> :
                        <Row gutter={10}>
                            <Col span={18}>
                                <Card title={
                                    <div className='card-title-icon'>
                                        <span className="material-symbols-outlined mr-2">
                                            monitoring
                                        </span> Monthly Sales Summary
                                    </div>
                                }>
                                    <Row>
                                        <Col span={8}>
                                            
                                            <a to="#">
                                                <Statistic
                                                    title="Total Sale"
                                                    prefix="Rs."
                                                    value={MonthlyTotalSales}
                                                    className='statistic-font-large'
                                                />
                                            </a>
                                        </Col>
                                        <Col span={8}>
                                            <Statistic
                                                title="Petrol Sale"
                                                prefix="Ltr."
                                                value={PetrolSaleQuantity}
                                                className='statistic-font-large'
                                            />
                                        </Col>
                                        <Col span={8}>
                                            <Statistic
                                                title="Diesel Sale"
                                                prefix="Ltr."
                                                value={DieselSaleQuantity}
                                                className='statistic-font-large'
                                            />
                                        </Col>

                                    </Row>
                                </Card>
                            </Col>
                            <Col span={6}>
                                <Card title="Your Cash in Hand">

                                <h1 style={{ fontSize: '20px' }}>Rs. {CashInHand}</h1>

                                    {/* <Statistic
                                        value={`Rs.  ${CashInHand}`}
                                        className='statistic-font-large'
                                    /> */}
                                </Card>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        </>
    )
}

export default Sales
