import React, { useState } from "react";
import { Row, Col, Button, Form, Input, Select, Card, Upload } from "antd";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const Inbox = () => {
  return (
    <>
      <div style={{ marginTop: "20px" }}>
        <Card>
          <div className="inbox-content">
            <ul>
              <Row gutter={[20,20]}>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Billing & Payments</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Technical Support</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link >
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Account Management</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Sales & Subscriptions</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Product Support</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Training & Onboarding</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Feedback & Suggestions</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Compliance & Security</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
                <Col sm={24} md={8}>
                  <Link>
                    <li>
                      <div>
                        <InformationCircleIcon width={"30px"} height={"30px"} />
                        <h3>Compliance & Security</h3>
                      </div>
                      <p>0</p>
                    </li>
                  </Link>
                </Col>
              </Row>
            </ul>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Inbox;
