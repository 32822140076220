import React, { useEffect, useState } from "react";
import {
  Space,
  Table,
  Popconfirm,
  Pagination,
  Row,
  Col,
  Breadcrumb,
  Button,
  Checkbox,
  Input,
  DatePicker,
  Form,
  Select,
  message,
  Tooltip,
} from "antd";
import { NavLink, useParams } from "react-router-dom";
import axios from "axios";
import config from "../../config";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import moment from "moment";
import LoginChecked from "../Shared/LoginChecked";

function ManageUsers() {
  const [messageApi, contextHolder] = message.useMessage();
  const params = useParams();
  const [update, setUpdate] = useState(false);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [listOfUsers, SetlistOfUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Isdeleted, setIsdeleted] = useState(false);

  useEffect(() => {
    document.title = "Manage Users";
  }, []);
  const confirm = (ID) => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/DeleteUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    console.log("🚀 ~ confirm tttttt~ api_config.data:", api_config.data);

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });
          setIsdeleted(true);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleSubmit = (ID) => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: ID,
    };
    console.log("🚀 ~ handleSubmit yyyyyyyyy~ data:", data);
    console.log(data);
    const api_config = {
      method: "post",
      url: config.base_url + "Users/DisableUser",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        // setValidated(true);

        console.log(error);
      });
  };

  useEffect(() => {
    setLoading(true);
    setIsdeleted(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Users/GetAllUsers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          // Handle success
          SetlistOfUsers(response.data.listofUsers);
          setLoading(false);
        } else {
          // Handle error
          setLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [Isdeleted]);

  const columns = [
    {
      title: "Sr.#",
      dataIndex: "Sr",
      key: "Sr",
      render: (_, record, index) => index + 1,
    },
    {
      title: "Full Name",
      dataIndex: "FullName",
      key: "FullName",
    },
    {
      title: "Mobile No",
      dataIndex: "User_Mobile",
      key: "User_Mobile",
    },
    {
      title: "Email Address",
      dataIndex: "User_Name",
      key: "User_Name",
    },

    {
      title: "User Type",
      dataIndex: "User_Type",
      key: "User_Type",
    },
    {
      title: "Active",
      dataIndex: "Is_Active",
      key: "Is_Active",
      render: (isActive) =>
        isActive ? (
          <CheckCircleFilled style={{ color: "green" }} />
        ) : (
          <CloseCircleFilled style={{ color: "gray" }} />
        ),
    },
    {
      title: "Deleted",
      dataIndex: "Is_Deleted",
      key: "Is_Deleted",
      render: (isDeleted) =>
        isDeleted ? (
          <CheckCircleFilled style={{ color: "green" }} />
        ) : (
          <CloseCircleFilled style={{ color: "gray" }} />
        ),
    },
    {
      title: "Created On",
      dataIndex: "Created_Date",
      key: "Created_Date",
      render: (createdOn) => {
        const formattedDate = createdOn
          ? moment(createdOn).format("DD-MM-YYYY")
          : moment().format("DD-MM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Last Password Changed",
      dataIndex: "LastPasswordChange",
      key: "LastPasswordChange",
      render: (lastPasswordChange) => {
        const formattedDate = lastPasswordChange
          ? moment(lastPasswordChange).format("DD-MM-YYYY")
          : moment().format("DD-MM-YYYY");
        return <span>{formattedDate}</span>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <NavLink to={`/security/edit-user/${record.ID}`}>
            <Tooltip title="Edit User">
              <FormOutlined />
            </Tooltip>{" "}
          </NavLink>

          <Popconfirm
            title="Sure to Disable?"
            onConfirm={() => handleSubmit(record.ID)}
          >
            <Tooltip title="Disable">
                <ExclamationCircleOutlined />
            </Tooltip>
          </Popconfirm>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => confirm(record.ID)}
          >
            <NavLink to="#">
              <Tooltip title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Manage User</h3>

            <div className="header-actions">
              <NavLink to={`/security/add-user`}>
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  icon={<PlusOutlined style={{ color: "#fff" }} />}
                >
                  Add New User
                </Button>
              </NavLink>
            </div>
          </div>

          <Table
            size="small"
            loading={loading}
            scroll={{
              x: "100%",
            }}
            bordered
            columns={columns}
            dataSource={listOfUsers}
            pagination={false}
          />
        </div>
      </div>
    </>
  );
}

export default ManageUsers;
