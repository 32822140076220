import React, { useEffect, useState } from "react";
import HeaderPrint from "../../../Pdf/PrintHeaderAccount";
import axios from "axios";
import config from "../../../config";
import { calculateColumnTotal } from "../../Shared/Utility";

import PrintFooter from "../../../Pdf/PrintFooter";
import fontRoboto from '../../../Pdf/fonts/Roboto_Condensed/static/Roboto-Regular.ttf';
import fonts from '../../../Pdf/fonts/Roboto_Condensed/static/RobotoCondensed-Regular.ttf';
import {PrintFormateNumber} from "../../../Pdf/PrintFormateNumber";
import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from "@react-pdf/renderer";
import dayjs, { locale } from "dayjs";
import { Spin } from "antd";

const PrintDateWiseSummaryAll = () => {
  const UserID = localStorage.getItem("ID");
  const AccessKey = localStorage.getItem("AccessKey");
  const urlParams = new URLSearchParams(window.location.search);

  const PageNo = urlParams.get("currentPage");
  const EndDate = urlParams.get("EndDate");
  const StartDate = urlParams.get("StartDate");
  const OrderBy = urlParams.get("orderby");

  const [DateWiseSummaryList, setDateWiseSummaryList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/DateWiseSummaryReport",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setDateWiseSummaryList(response.data.ListofSummary);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  Font.register({
  family: 'Roboto Condensed', 
  src: fonts, 
});
Font.register({
    family: 'Roboto',
    src: fontRoboto, 
  });

  const styles = StyleSheet.create({
     page: {
    backgroundColor: "#FFF",
    fontFamily: 'Roboto Condensed',
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: "column",
    marginBottom: 20,
  },
  header: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottomWidth: 0.5,
  },
  logoside: {
    marginRight: "130px",
    marginLeft: "20px",
  },
  headerinfo:{
    fontFamily: 'Roboto Condensed',
    fontWeight:'bold',
    fontSize:'18pt'
  },
  headercontent: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  headertitle: {
    fontSize: "15pt",
    marginTop: "5px",
    fontFamily: 'Roboto Condensed',
    fontWeight:'bold'
  },
  contentwrap: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  contenttitle: {
    fontSize: "10pt",
    marginBottom: 5,
    marginRight: 5,
  },
  contentimg: {
    width: 10,
    height: 10,
    marginBottom: 4,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  pagetitle:{
    fontSize:'15pt',
    fontWeight:'bold'
  },

  table: {
    display: "flex",
    width: "100%",
    marginBottom: 10,
  },
  tableHead: {
    flexDirection: "row",
    backgroundColor: "rgba(0,0,0,0.05)",
  },
  th: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 0.2,
    borderColor: "#000",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  thValue: {
    fontSize: "8pt",
    fontWeight: "bold",
    fontFamily:'Roboto'
  },
  row: {
    flexDirection: "row",
  },
  td: {
    padding: 5,
    borderStyle: "solid",
    borderWidth: 0.2,
    borderColor: "#000",
  },
  tdValue: {
    fontSize: "8pt",
    color: "black",
    fontFamily:'Roboto'
  },
  pageBreak: {
    marginBottom: 30,
    breakAfter: 'always',
  },
  footer: {
    padding: "10px 20px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    borderTopWidth: 1,
    borderTopColor: '#ccc',
    fontSize: '10pt',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center',
  },
  footerText: {
    textAlign: 'center',
    color: '#333',
    fontSize: '9pt',
  },
  companyText: {
    marginTop: 5,
    fontSize: '9pt',
    color: '#555',
  },
  });

  const generateData = () => {
    if (DateWiseSummaryList.length > 0) {
      return DateWiseSummaryList.map((item, index) => {
        return (
          <View key={index} style={styles.row}>
            <View style={[styles.td, { width: 30 }]}>
              <Text style={styles.tdValue}>{index + 1}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>
                {dayjs(item.Dated).format(config.date_format)}
              </Text>
            </View>
            <View style={[styles.td, { width: 100 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Opening_Cash,0)}</Text>
            </View>

            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Cash_Sales,0)}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Credit_Sales,0)}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={[styles.tdValue, { textAlign: "right" }]}>
                {PrintFormateNumber(item.Cash_Purchase,0)}
              </Text>
            </View>
            <View style={[styles.td, { width: 70 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Credit_Purchase,0)}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Total_Receipts,0)}</Text>
            </View>
            <View style={[styles.td, { width: 110 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Total_Payments,0)}</Text>
            </View>
            <View style={[styles.td, { width: 90 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Total_Expenses,0)}</Text>
            </View>
            <View style={[styles.td, { width: 80 }]}>
              <Text style={styles.tdValue}>{PrintFormateNumber(item.Closing_Cash,0)}</Text>
            </View>
          </View>
        );
      });
    }
  };

  const MyDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <HeaderPrint />

        <View style={styles.section}>
          <View style={styles.title}>
            <Text style={styles.pagetitle}>DateWise Summary</Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHead}>
              <View style={[styles.th, { width: 30 }]}>
                <Text style={styles.thValue}>SR.</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Dated</Text>
              </View>
              <View style={[styles.th, { width: 100, alignItems: "right" }]}>
                <Text style={[styles.thValue,{textAlign:'right'}]}>Opening Cash</Text>
              </View>

              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Cash Sales</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Credit Sales</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={[styles.thValue, { textAlign: "right" }]}>
                  Cash Purchases
                </Text>
              </View>
              <View style={[styles.th, { width: 70 }]}>
                <Text style={styles.thValue}>Credit Purchases</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Total Receipts</Text>
              </View>
              <View style={[styles.th, { width: 110 }]}>
                <Text style={styles.thValue}>Total Payments</Text>
              </View>
              <View style={[styles.th, { width: 90 }]}>
                <Text style={styles.thValue}>Total Expenses</Text>
              </View>
              <View style={[styles.th, { width: 80 }]}>
                <Text style={styles.thValue}>Closing Cash</Text>
              </View>
            </View>

            {generateData()}
          </View>
          <View
            style={{ marginBottom: 50, marginTop: 0, top: 10, bottom: 10 }}
            fixed
          >
            <Text
              style={{ fontSize: "8pt" }}
              render={({ pageNumber, totalPages }) =>
                `Page ${pageNumber} / ${totalPages}`
              }
            />
          </View>
        </View>
        <PrintFooter fixed />
      </Page>
    </Document>
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
        {loading ? (
          <div
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin />
          </div>
        ) : (
          <PDFViewer width="100%" height="100%" fileName="CashReceivable.pdf">
            <MyDocument />
          </PDFViewer>
        )}
      </div>
    </>
  );
};

export default PrintDateWiseSummaryAll;
