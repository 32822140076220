import React, { useState, useEffect } from "react";
import config from "../../config";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";
import {
  Button,
  Col,
  Select,
  Form,
  Input,
  Row,
  message,
  Spin,
  Space,
} from "antd";
import { NavLink } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
function AddNewProduct() {
  const [messageApi, contextHolder] = message.useMessage();

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const { Option } = Select;
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "Add New Products";
  }, []);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ...formData,
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Product/AddNewProduct",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        {loading ? (
          <div className="spin-center">
            {" "}
            <Space size="large">
              {" "}
              <Spin size="large" />{" "}
            </Space>{" "}
          </div>
        ) : (
          <div className="page-content">
            <div className="page-header">
              <h6 className="page-title">
                <NavLink to="/products/manage-product">
                  <ChevronLeftIcon />
                </NavLink>
                Add New Product
              </h6>
            </div>

            <Row gutter={[24, 24]} justify={"center"}>
              <Col md={12} xs={24}>
                <Form
                  name="basic"
                  layout="horizontal"
                  size="large"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                  autoComplete="off"
                  loading={loading}
                >
                  <Form.Item
                    label="Product Code"
                    name="ProductCode"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name!",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your product code" />
                  </Form.Item>

                  <Form.Item
                    name="MeasureUnitID"
                    label="Measure Unit"
                    rules={[
                      {
                        message: "Please enter the measure unit",
                        required: true,
                      },
                    ]}
                  >
                    <Select placeholder="Select a measure unit" allowClear>
                      <Option value="1">Litre</Option>
                      <Option value="2">Gallon</Option>
                      <Option value="3">Piece</Option>
                      <Option value="4">Pound</Option>
                      <Option value="5">KG</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Product Name"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please input your product name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your product name" />
                  </Form.Item>
                  <Form.Item
                    label="Sale Price"
                    name="Sale_Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input sales price",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your sale price" />
                  </Form.Item>
                  <Form.Item
                    label="Last Purchase"
                    name="Last_Purchase_Price"
                    rules={[
                      {
                        required: true,
                        message: "Please input last price",
                      },
                    ]}
                  >
                    <Input placeholder="Enter your last purchase" />
                  </Form.Item>
                  <Form.Item label="Descriptionn" name="Description">
                    <Input placeholder="Enter your description" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                    >
                      {loading ? <Spin /> : "Add Product"}
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        )}
      </div>
    </>
  );
}

export default AddNewProduct;
