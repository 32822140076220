import React, { useEffect, useState } from "react";
import axios from "axios";
import StatBox from "./StatBox";
import config from "../../config";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Form,
  Skeleton,
  Card,
  Table,
} from "antd";
import { NavLink } from "react-router-dom";

import { PrinterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import LoginChecked from "../Shared/LoginChecked";
import { NumberFormat } from "../Shared/Utility";
function FinancialSummary() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [StartDate, SetStartDate] = useState(dayjs().subtract(1, "month"));
  const [FinalData, setFinalData] = useState({});
  const [EndDate, SetEndDate] = useState(dayjs());

  useEffect(() => {
    document.title = "Financial Summary";

    setLoading(true);
    const data = {
      UserId: UserID,
      AccessKey: AccessKey,
    //   StartDate: StartDate,
    //   EndDate: EndDate,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetFinancialSummary",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log("this is summarysheet data", response.data);
        if (response.data.status_code == 1) {
          setFinalData(response.data);
          // SetStartDate(response.data)
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [EndDate, StartDate]);

  const { RangePicker } = DatePicker;

  const handleDateChange = (date, value) => {
    console.log(value);
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate=(current)=>{
    return current && current > moment().endOf('day');
  }

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
            Financial Summary
            </h3>

            <div className="header-actions">
             
              <NavLink
                to={`/report-final/print-list`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Print Report
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filter-wrap">
            <Form>
              <Form.Item>
                <RangePicker allowClear onChange={handleDateChange}
                disabledDate={disabledDate} />
              </Form.Item>
            </Form>
          </div>
          {loading ? (
            <>
              <Row>
                <Col xs={24} md={16}>
                  <Skeleton active />
                  <Skeleton active />
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div
                style={{
                  border: "0.5px solid rgb(229 229 229 / 89%)",
                  borderRadius: "5px",
                  padding: "5px 10px",
                  fontFamily: "Roboto",
                }}
              >
                <p style={{ color: "rgb(165 164 164 / 89%)" }}>
                FINANCIAL SUMMARY DETAILS
                </p>
                <table
                  style={{
                    borderCollapse: "collapse",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                >
                  <tr>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      Total Amount Of All Products
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      {FinalData.productBalance ? NumberFormat(FinalData.productBalance,0)  : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      Total Customer Amount Receivable 
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      {FinalData.customerReceiveable
                        ? NumberFormat(FinalData.customerReceiveable,0)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      Total Bank Amount
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      {FinalData.bankBalance ? NumberFormat(FinalData.bankBalance,0) : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" ,
                    borderBottom:"1px solid #bdb8b8"
                  }}>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      Total Cash In Hand
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      {FinalData.cashBalance ? NumberFormat(FinalData.cashBalance,0)  : 0}
                    </td>
                  </tr>
                  <tr style={{borderBottom:"1px solid #bdb8b8"}}>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      Total Amount
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      {FinalData ? NumberFormat((FinalData.productBalance + FinalData.customerReceiveable + FinalData.bankBalance + FinalData.cashBalance ),0) : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" }}>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      Total Payable Amount Of Supplier
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      {FinalData.supplierPayable
                        ? NumberFormat(FinalData.supplierPayable,0)
                        : 0}
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      Total Capital Amount
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",
                      }}
                    >
                      {FinalData.capitalBalance
                        ? NumberFormat(FinalData.capitalBalance,0)
                        : 0}
                    </td>
                  </tr>
                  <tr style={{ backgroundColor: "rgb(235 235 235 / 38%)" ,borderTop:"1px solid #bdb8b8"}}>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      Total Gross Amount
                    </td>
                    <td
                      style={{
                        padding: "10px 10px",

                        width: "50%",
                      }}
                    >
                      {FinalData
                        ? NumberFormat(((FinalData.productBalance + FinalData.customerReceiveable + FinalData.bankBalance + FinalData.cashBalance ) - (FinalData.supplierPayable + FinalData.capitalBalance)),0)
                        : 0}
                    </td>
                  </tr>
                 
                </table>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default FinancialSummary;
