import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import ProductsMenu from "../../Products/ProductsMenu";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import LoginChecked from "../../Shared/LoginChecked";
import {
  Button,
  Col,
  Spin,
  Popconfirm,
  Row,
  Space,
  Table,
  DatePicker,
  Form,
  Pagination,
} from "antd";
import axios from "axios";
import config from "../../../config";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { PrinterOutlined, EditOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {Stock,Payable,Cash} from "../../UserControls/Icons"


function ProductLedger() {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);

  const product_id = urlParams.get("product_id");

  const { RangePicker } = DatePicker;
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);

  const [Product, SetProduct] = useState({});

  let params = useParams();
  const [ListofProductLedger, SetListofProductLedger] = useState([]);
  const [StartDate, SetStartDate] = useState(
    dayjs().subtract(30, "day").format("YYYY-MM-DD")
  );
  const [EndDate, SetEndDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [orderby, SetOrderBy] = useState("1");
  const [AccountIdData, setAccountIdData] = useState("");
  const[data,setData]=useState({});

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);


  useEffect(() => {
    document.title = "Product Ledger";
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      ID: product_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          SetProduct(response.data.Product);
          setAccountIdData(response.data.Product);
        } else {
        }

        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: orderby,
      PageNo: pageNo,
      PageSize: pageSize,
      ID: product_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetProductLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {
          // Handle success
          SetListofProductLedger(response.data.listofProductLedger);
          setTotalRecords(response.data.totalRecords);
          setData(response.data.Product)

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageNo, pageSize]);

  const goBack = () => {
    navigate(-1);
  };

  const columns = [
    {
      title: "Sr.# ",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
    },
    {
      title: "Dated",
      dataIndex: "Transaction_Date",
      key: "Transaction_Date",
      // render: (transactionDate) => (
      //     <span>{transactionDate ? moment(transactionDate).format("DD-MM-YYYY") : moment().format("DD-MM-YYYY")}</span>
      //   ),
      render: (transactionDate) => {
        const formattedDate = transactionDate
          ? moment(transactionDate).format(config.date_format)
          : moment().format(config.date_format);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: " In	",
      dataIndex: "Debit",
      key: "Debit",
    },
    {
      title: "Out",
      dataIndex: "Credit",
      key: "Credit",
    },
    {
      title: "Balance	",
      dataIndex: "Balance",
      key: "Balance",
      align: "right",
    },
    {
      title: "BalanceType",
      dataIndex: "BalanceType",
      key: "BalanceType",
    },

    {
      title: "Ref.#",
      dataIndex: "Reference_Type",
      key: "Reference_Type",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        if (record.Reference_Type === "Sale") {
          return (
            <Space size="middle">
              <NavLink
                to={`/sales/edit-sales/${record.Reference_ID}`}
                target="_blank"
              >
                <EditOutlined />
              </NavLink>
            </Space>
          );
        } else if (record.Reference_Type === "Purchase") {
          return (
            <Space size="middle">
              <NavLink
                to={`/purchase/edit-purchase/${record.Reference_ID}`}
                target="_blank"
              >
                <EditOutlined />
              </NavLink>
            </Space>
          );
        } else if (record.Reference_Type === "DIP") {
          return (
            <Space size="middle">
              <NavLink
                to={`/dipsettings/edit-tank/${record.Reference_ID}`}
                target="_blank"
              >
                <EditOutlined />
              </NavLink>
            </Space>
          );
        } else {
          return <span>Other Type</span>;
        }
      },
    },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1);
    setPageSize(size);
  };

  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            {/* <div>
              <div style={{ display: 'flex', alignItems: 'baseline', gap: '10px' }}>
                <NavLink onClick={goBack}>
                  <ChevronLeftIcon style={{ width: '20px' }} />
                </NavLink>
                <strong style={{ fontSize: '18px' }}>Product Ledger : </strong>
                <span className="page-title ">{AccountIdData?.Name} ({AccountIdData?.ProductCode})</span>
              </div>
              <ul className="letter-head-style-header">
                <li><strong>Sale Price : </strong> <span>{AccountIdData?.Sale_Price}</span></li>
                <li><strong>Last Purchase Price : </strong> <span>{AccountIdData?.Last_Purchase_Price}</span></li>
                <li><strong>Current Balance : </strong> <span>{AccountIdData?.Balance}</span></li>
              </ul>
            </div> */}

            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <NavLink onClick={goBack}>
                  <ChevronLeftIcon style={{ width: "20px" }} />
                </NavLink>
                <span className="page-title ">{AccountIdData?.Name}</span>
                <strong className="gernal-leader"> Product Ledger </strong>
               
              </div>
              <ul className="ledger-header">
                <li className="vertical-divider">
                 
                  <Cash className="img-icon" />
                  <div className="ledger-content">
                    <strong>Sale Price </strong>{" "}
                    <span>{AccountIdData?.Sale_Price}</span>
                  </div>
                </li>

                <li className="vertical-divider">
                  
                  <Payable className="img-icon" />
                  <div className="ledger-content">
                    <strong>Last Purchase Price </strong>{" "}
                    <span>
                    {AccountIdData?.Last_Purchase_Price}
                    </span>
                  </div>
                </li>

                <li>
                  <Stock className="img-icon" />
                  <div className="ledger-content">
                    <strong>Current Balance </strong>{" "}
                    <span>
                    {/* {AccountIdData?.Balance} */}
                    {data.Balance} Ltr
                    </span>
                  </div>
                </li>
              </ul>
            </div>

            <div className="header-actions">
              <NavLink
                to={`/product/print-product-ledger/?product_id=${product_id}&StartDate=${StartDate}&EndDate=${EndDate}`}
                target="_blank"
              >
                <Button
                  type="dashed"
                  size="large"
                  shape="round"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>
            </div>
          </div>
          <div className="filters-wrap">
            <Form>
              <RangePicker
                defaultValue={[
                  dayjs(StartDate, "YYYY/MM/DD"),
                  dayjs(EndDate, "YYYY/MM/DD"),
                ]}
                allowClear
                onChange={handleDateChange}
                disabledDate={disabledDate}
              />
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: "100%",
            }}
            bordered
            loading={loading}
            dataSource={ListofProductLedger}
            columns={columns}
            pagination={false}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default ProductLedger;
