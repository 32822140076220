import React, { useState, useEffect } from "react";
import ProductsDropDown from "../Shared/ProductsDropDown";
import config from "../../config";
import axios from "axios";
import LoginChecked from "../Shared/LoginChecked";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Spin,
  message,
} from "antd";
import { NavLink, useParams } from "react-router-dom";

function EditTank() {
  let params = useParams();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const accountTypesArray = ProductsDropDown();
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();
  const [ProductName, setProductName] = useState("");
  const [Tank, setTank] = useState({});
  const [update, setUpdate] = useState(false);
  const [loadingGetTank, setLoadingGetTank] = useState(false);

  const [form] = Form.useForm();
  useEffect(() => {
    document.title = "Edit Tank";
    setLoadingGetTank(true);
    setUpdate(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    
    var api_config = {
      method: "post",
      url: config.base_url + "Product/GetTankByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
     
        if (response.data.status_code == 1) {
          setLoadingGetTank(false);
          setTank(response.data.Product);
          form.setFieldsValue(response.data.Tank);
        } else {
          setLoadingGetTank(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [update]);

  const handleSubmit = (formData) => {
    setLoading(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ProductName: ProductName,
      ID: params.id,
      ...formData,
    };

   

    var api_config = {
      method: "post",
      url: config.base_url + "Product/UpdateTank",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

   

    axios(api_config)
      .then(function (response) {
       
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleProductChange = (value, option) => {
    setProductName(option.label);
  };

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/dipsettings/manage-tanks">
                <ChevronLeftIcon />
              </NavLink>
              Edit Tank
            </h6>
            <div className="header-actions">
              <NavLink to="/dipsettings/manage-tanks">
                <Button
                  type="primary"
                  size="large"
                  shape="round"
                >
                  Manage Tanks
                </Button>
              </NavLink>
            </div>
          </div>
          <Row justify={"center"}>
            <Col md={12} xs={24} >
              
                <Form
                  name="basic"
                  form={form}
                  layout="horizontal"
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  initialValues={{
                    remember: true,
                  }}
                  onFinish={handleSubmit}
                  //    onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  // fields={[
                  //   {
                  //     name: "TankNo",
                  //     value: Tank.TankNo,
                  //   },
                  //   {
                  //     name: "ProductID",
                  //     value: Tank.ProductID,
                  //   },
                  //   {
                  //     name: "TankFullCapacity",
                  //     value: Tank.TankFullCapacity,
                  //   },
                  //   {
                  //     name: "UseableCapacity",
                  //     value: Tank.UseableCapacity,
                  //   },
                  //   {
                  //     name: "TankShape",
                  //     value: Tank.TankShape,
                  //   },

                  //   {
                  //     name: "TankSizeDeails",
                  //     value: Tank.TankSizeDeails,
                  //   },
                  // ]}
                >
                  <Form.Item
                    label="Tank Number"
                    name="TankNo"
                    rules={[
                      {
                        required: true,
                        message: "Please enter tank number",
                      },
                    ]}
                  >
                    <Input placeholder="Enter tank number" />
                  </Form.Item>
                  <Form.Item
                    label="Product"
                    name="ProductID"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select your product"
                      optionFilterProp="children"
                      onChange={handleProductChange}
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={accountTypesArray === false ? true : false}
                      options={accountTypesArray}
                    />
                  </Form.Item>
                  <Form.Item label="Tank Capacity" name="TankFullCapacity">
                    <Input placeholder="Enter full capacityr" />
                  </Form.Item>
                  <Form.Item
                    label="Useable Capacity"
                    name="UseableCapacity"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Usable Capacity",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Used Capcity" />
                  </Form.Item>
                  <Form.Item
                    label="Shape Details"
                    name="TankShape"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Tank shape",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Tank Shape" />
                  </Form.Item>
                  <Form.Item
                    label="Size details"
                    name="TankSizeDetails"
                    rules={[
                      {
                        required: true,
                        message: "Please enter size detail",
                      },
                    ]}
                  >
                    <Input placeholder="Enter size details" />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      size="large"
                      shape="round"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Add Tank"}
                    </Button>
                  </Form.Item>
                </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditTank;
