import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import axios from "axios";
import config from "../../config";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

import AccountDropDown from "../Shared/AccountDropDown";
import LoginChecked from "../Shared/LoginChecked";
import AccountTypesDropdown from "../Shared/AccountTypesDropdown";
import {
  Row,
  Col,
  Card,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  message,
  Spin,
  Collapse,
  Breadcrumb,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { NavLink, useParams } from "react-router-dom";

function EditAccount(props) {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [Account, setAccount] = useState({});
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  let params = useParams();

  const [form] = Form.useForm();

  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();

  const accountTypesArray = AccountTypesDropdown();

  const [loadingGetAccount, setLoadingGetAccount] = useState(false);

  useEffect(() => {
    document.title = "Edit Account";
    setLoadingGetAccount(true);
    setUpdate(false);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: params.id,
    };

    // console.log(data);
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLoadingGetAccount(false);
          setAccount(response.data);
        } else {
          setLoadingGetAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [update]);

  const handleSubmit = (formData) => {
    setLoading(true);

    formData["OpeningDate"] = moment(formData.OpeningDate).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: params.id,
      ...formData,
    };

    // console.log("data_account", data);

    var api_config = {
      method: "post",
      url: config.base_url + "account/UpdateNewAccount",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    // console.log(api_config);

    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setLoading(false);
          setUpdate(true);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          // Handle success
        } else {
          // Handle error
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const text = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Opening balance with Debit type for customers will automatically be added
      in the system, However, opening balance for all other balance types and
      accounts will be managed through vouchers.
    </p>
  );
  const text1 = (
    <p
      style={{
        paddingLeft: 24,
      }}
    >
      Opening balance with Credit type for customers will be automatically added
      in the system, However, opening balance for all other balance types and
      accounts will be managed through vouchers.
    </p>
  );
  const items = [
    {
      key: "1",
      label: "Opening Balance for Customers",
      children: text,
    },
    {
      key: "2",
      label: "Opening Balance for Suppliers",
      children: text1,
    },
  ];

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              <NavLink to="/accounts/manage-accounts">
                <ChevronLeftIcon />
              </NavLink>
              Edit Account
            </h3>
          </div>

          <Row gutter={[24, 24]}>
            <Col xs={24} md={12} span={12}>
              <Card loading={loadingGetAccount}>
                <div
                  className="card-title-icon"
                  style={{ marginBottom: "24px" }}
                >
                  {/* <div className="icon">
                    <span className="material-symbols-outlined">
                      manage_accounts
                    </span>
                  </div> */}
                  
                </div>
                <Form
                  name="basic"
                  layout="horizontal"
                  form={form}
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  onFinish={handleSubmit}
                  autoComplete="off"
                  fields={[
                    {
                      name: "Name",
                      value: Account.Name,
                    },
                    {
                      name: "Mobile_No",
                      value: Account.Mobile_No,
                    },
                    {
                      name: "Phone_No",
                      value: Account.Phone_No,
                    },
                    {
                      name: "Email_Address",
                      value: Account.Email_Address,
                    },
                    {
                      name: "Description",
                      value: Account.Description,
                    },

                    {
                      name: "Account_Type_ID",
                      value: Account.Account_Type_ID,
                    },
                  ]}
                >
                  <Form.Item
                    label="Account Name"
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: "Please enter account name",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Account Name" />
                  </Form.Item>

                  <Form.Item
                    label="Mobile Number"
                    name="Mobile_No"
                    rules={[
                      {
                        required: true,
                        message: "Please input your Mobile No",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Mobile Number" />
                  </Form.Item>
                  <Form.Item label="Phone Number" name="Phone_No">
                    <Input placeholder="Enter Phone Number" />
                  </Form.Item>
                  <Form.Item label="Email Address" name="Email_Address">
                    <Input placeholder="Enter Email Address" />
                  </Form.Item>
                  <Form.Item label="Description" name="Description">
                    <Input placeholder="Enter Your Description" />
                  </Form.Item>

                  <Form.Item
                    label="Account Type"
                    name="Account_Type_ID"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    rules={[
                      {
                        required: true,
                        message: "Please select account type!",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select account type"
                      optionFilterProp="children"
                      // onChange={onChange}
                      // onSearch={onSearch}
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      loading={accountTypesArray === false ? true : false}
                      options={accountTypesArray}
                    />
                  </Form.Item>

                  <Form.Item
                    wrapperCol={{
                      offset: 8,
                      span: 16,
                    }}
                  >
                    <Button
                      type="primary"
                      shape="round"
                      size="large"
                      htmlType="submit"
                      loading={loading}
                    >
                      {loading ? <Spin /> : "Updated"}
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
            <Col xs={24} md={12} span={12}>
              <Collapse
                items={items}
                bordered={true}
                defaultActiveKey={["1", "2"]}
              />
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default EditAccount;
