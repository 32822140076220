import { Form, Modal, Checkbox, Input, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import LoginChecked from "../Shared/LoginChecked";
import config from "../../config";
import axios from "axios";
import { KeyOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";





function ResetAccount() {

  let navigate = useNavigate();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [loadingReset, setloadingReset] = useState(false);
  const [data, setData] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };



  const [checkboxStates, setCheckboxStates] = useState({
    DeletePurchase: false,
    DeleteSale: false,
    DeleteGeneralLedger: false,
    DeleteProductLedger: false,
    DeleteVouchers: false,
    DeleteDipReadings: false,
    DeleteNozleReading: false,
    DeleteNozells: false,
    DeleteCustomerRates: false,
    DeleteInvestmentSummary: false,
    DeleteProducts: false,
    DeleteAccounts: false,
    DeleteLoginLogs: false,
  });







  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Account/GetResetInfo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setData(response.data.Contents);
          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  }, []);


  const handleCheckboxChange = (key) => {
    setCheckboxStates((prevState) => ({
      ...prevState,
      [key]: !prevState[key], // Toggle the value
    }));
  };


  const ResetData = (formData) => {

    setloadingReset(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ...formData,
      ...checkboxStates
    };

    console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Account/ResetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          navigate('/dashboard/analytics');
          setloadingReset(true);
        } else {
          setloadingReset(true);
          message.error(response.data.status_message);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });


  }


  const SendOtp = () => {

    setLoadingSendOtp(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "OTP/SendResetOTP",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          setLoadingSendOtp(false);
          message.success(response.data.status_message);
          setIsModalOpen(true);
        } else {
          setLoadingSendOtp(false);
          message.error(response.data.status_message);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
      });
  }




  const checkAll = (isChecked) => {
    setCheckboxStates((prevState) => {
      const updatedState = {};
      Object.keys(prevState).forEach((key) => {
        updatedState[key] = isChecked; // Set all checkboxes to the value of isChecked
      });
      return updatedState;
    });
  };

  return (
    <>

      <LoginChecked />

      <Modal
        title="Reset Account"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <p>An OTP has been sent to your registered email address. Please check your inbox and enter the OTP to proceed</p>

        <Form
          size="large"
          layout="vertical"
          onFinish={ResetData}
        >

          <Form.Item
            name="OTPCode"
            label="OTP"
            rules={[
              {
                required: true,
                message: "Please enter OTP",
              },
            ]}
          >
            <Input
              prefix={
                <KeyOutlined />
              }
              placeholder="OTP Code"
            />
          </Form.Item>

          <Form.Item>
            <Button
              style={{ width: "100%" }}
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={loadingReset}
              shape="round"
            >
              Reset Account
            </Button>


          </Form.Item>


        </Form>
        <p>
          ⚠️ Warning: This action will permanently delete all selected data from Fuelbook. This cannot be undone. Please proceed only if you are absolutely certain.
        </p>
      </Modal>


      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">
              Reset Account
            </h3>
          </div>
          <div className="custom-table" style={{ maxWidth: 500 }}>
            <table className="table">
              <thead>
                <tr>
                  <th><Checkbox onChange={(e) => checkAll(e.target.checked)}>Module Name</Checkbox></th>
                  <th>No. of Records</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <td colSpan={2}>Loading...</td>
                ) : (
                  <>
                  <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeletePurchase}
                          onChange={() => handleCheckboxChange("DeletePurchase")}
                        >
                          Purchase
                        </Checkbox>
                      </td>
                      <td>{data.PurchaseHeadCount} / {data.PurchaseDetailCount} </td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteSale}
                          onChange={() => handleCheckboxChange("DeleteSale")}
                        >
                          Sales
                        </Checkbox>
                      </td>
                      <td>{data.SalesHeadCount} / {data.SaleDetailCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteVouchers}
                          onChange={() => handleCheckboxChange("DeleteVouchers")}
                        >
                          Vouchers
                        </Checkbox>
                      </td>
                      <td>{data.VoucherCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteGeneralLedger}
                          onChange={() => handleCheckboxChange("DeleteGeneralLedger")}
                        >
                          General Ledger
                        </Checkbox>
                      </td>
                      <td>{data.GeneralLedgerCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteProductLedger}
                          onChange={() => handleCheckboxChange("DeleteProductLedger")}
                        >
                          Product Ledger
                        </Checkbox>
                      </td>
                      <td>{data.ProductLedgerCount}</td>
                    </tr>

                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteProducts}
                          onChange={() => handleCheckboxChange("DeleteProducts")}
                        >
                          Products
                        </Checkbox>
                      </td>
                      <td>{data.ProductsCount}</td>
                    </tr>


                    <tr>
                      <td style={{ width: 300 }}>
                        <Checkbox
                          checked={checkboxStates.DeleteAccounts}
                          onChange={() => handleCheckboxChange("DeleteAccounts")}
                        >
                          Accounts
                        </Checkbox>
                      </td>
                      <td>{data.AccountsCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteLoginLogs}
                          onChange={() => handleCheckboxChange("DeleteLoginLogs")}
                        >
                          Login Logs
                        </Checkbox>
                      </td>
                      <td>{data.LoginLogsCount}</td>
                    </tr>

                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteDipReadings}
                          onChange={() => handleCheckboxChange("DeleteDipReadings")}
                        >
                          Dip Readings
                        </Checkbox>
                      </td>
                      <td>{data.DipReadingCount} / {data.DipReadingAdjCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteNozells}
                          onChange={() => handleCheckboxChange("DeleteNozells")}
                        >
                          Nozzles
                        </Checkbox>
                      </td>
                      <td>{data.PumpMachineCount}</td>
                    </tr>
                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteNozleReading}
                          onChange={() => handleCheckboxChange("DeleteNozleReading")}
                        >
                          Nozzle Readings
                        </Checkbox>
                      </td>
                      <td>{data.PumpReadingCount}</td>
                    </tr>

                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteCustomerRates}
                          onChange={() => handleCheckboxChange("DeleteCustomerRates")}
                        >
                          Customer Rates
                        </Checkbox>
                      </td>
                      <td>{data.CustomerRateCount}</td>
                    </tr>

                    <tr>
                      <td>
                        <Checkbox
                          checked={checkboxStates.DeleteInvestmentSummary}
                          onChange={() => handleCheckboxChange("DeleteInvestmentSummary")}
                        >
                          Investment Summary
                        </Checkbox>
                      </td>
                      <td>{data.InvestmentSummaryCount}</td>
                    </tr>
   
                  </>
                )}
              </tbody>
            </table>
            <div style={{ marginTop: 30 }}>
              <Button type="primary" shape="round" size="large" onClick={SendOtp} loading={loadingSendOtp}>Reset Data</Button>
            </div>
          </div>


        </div>
      </div>
    </>
  );
}

export default ResetAccount;
