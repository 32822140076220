import React, { useEffect, useState } from "react";
import moment from "moment";
import config from "../../config";
import axios from "axios";
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  message,
  Pagination,
  Table,
  Space,
  Tooltip,
  Popconfirm,
  Skeleton,
} from "antd";
import { NavLink } from "react-router-dom";
import LoginChecked from "../Shared/LoginChecked";
import AccountDropDown from "../Shared/AccountDropDown";
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import { NumberFormat } from "../Shared/Utility";

function ExpenseVoucher() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");

  const VoucherMode = "Expenses";

  const [voucherNo, setVoucherNo] = useState(0);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");
  const [messageApi, contextHolder] = message.useMessage();

  const { RangePicker } = DatePicker;

  const [isDeleted, setIsDeleted] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [open, setOpen] = useState(false);
  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [OrderBy, SetOrderBy] = useState("1");
  const [isVoucherUpdated, setIsVoucherUpdated] = useState(false);

  const [confirmLoading, setConfirmLoading] = useState(false);
  const [Button_Lodding, setButton_Lodding] = useState(false);

  const [ExpenseAccountLoading, setExpenseAccountLoading] = useState(false);
  const [ExpenseAccount, setExpenseAccount] = useState([]);

  const [FromAccountLoading, setFromAccountLoading] = useState(false);
  const [FromAccount, setFromAccount] = useState([]);

  const accounts = AccountDropDown();

  const [ModalType, setModalType] = useState("");
  const [RecAccount, setRecAccount] = useState({});
  const [CustomerAccount, setCustomerAccount] = useState({});
  const [creditAccountID, setcreditAccountID] = useState(0);
  const [debitAccountID, setdebitAccountID] = useState(0);
  const [CreditAccountLoading, setCreditAccountLoading] = useState(false);
  const [DebitAccountLoading, setDebitAccountLoading] = useState(false);

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchAccountsByType = (accountTypeID, setAccounts, setLoading) => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Account_Type_ID: accountTypeID,
    };
    const api_config = {
      method: "post",
      url: `${config.base_url}account/GetAccountsByType`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then((response) => {
        if (response.data.status_code === 1) {
          const result = response.data.listofAccounts.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));
          setAccounts(result);
        }
      })
      .catch((error) => {
        console.error("Error fetching accounts:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchAccountsByType(8, setExpenseAccount, setExpenseAccountLoading);
  }, []);


  useEffect(() => {
    fetchAccountsByType(-1, setFromAccount, setFromAccountLoading);
  }, []);

  useEffect(() => {
    document.title = "Receipt Vouchers";
    setIsDeleted(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: 0,
      Debit_Account_ID: 0,
      Credit_Account_ID: 0,
      VoucherMode: VoucherMode,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchersWithType",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          SetlistofVouchers(response.data.listofVouchers);
          setTotalRecords(response.data.totalRecords);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {});
  }, [
    OrderBy,
    isVoucherUpdated,
    StartDate,
    EndDate,
    pageSize,
    pageNo,
    isDeleted,
  ]);

  useEffect(() => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      VoucherMode: "Expenses",
    };
    var api_config = {
      method: "post",
      url: config.base_url + "/Voucher/GetNextVoucherNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    setVoucherNo(data.voucherNo);
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setVoucherNo(response.data.NextVoucherNo);
        }
      })
      .catch(function (error) {});
  }, []);

  const columns = [
    {
      title: "SR",
      render: (_, record, index) => index + 1 + pageSize * (pageNo - 1),
      key: "Sr",
    },
    {
      title: "Dated",
      dataIndex: "Dated",
      key: "dated",
      render: (date) => moment(date).format(config.date_format),
      sorter: true,
    },
    {
      title: "No",
      dataIndex: "VoucherNo",
      key: "VoucherNo",
      sorter: true,
    },
    {
      title: "Expense Account",
      dataIndex: "Debit_Account",
      key: "Debit_Account",
      render: (_, record) => {
        return record.Debit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Debit_Account_ID}`}
          >
            {record.Debit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "From Account",
      dataIndex: "Credit_Account",
      key: "Credit_Account",
      render: (_, record) => {
        return record.Credit_Account ? (
          <a
            target="_blank"
            href={`/accounts/account-ledger/?account_id=${record.Credit_Account_ID}`}
          >
            {record.Credit_Account.Name}
          </a>
        ) : (
          ""
        );
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "description",
    },
    {
      title: "Amount",
      dataIndex: "Amount",
      key: "Amount",
      align: "right",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a onClick={() => handelShowModal("Edit", record)}>
            <Tooltip title="Edit Voucher">
              <EditOutlined />
            </Tooltip>
          </a>
          <Popconfirm
            title="Sure to delete?"
            description="Are You sure to delete this data"
            onConfirm={() => handelDelete(record.ID)}
          >
            <NavLink to={`#`}>
              <Tooltip title="Delete Voucher">
                <DeleteOutlined />
              </Tooltip>
            </NavLink>
          </Popconfirm>
          <NavLink
            to={`/accounts/print-voucher-list/?voucher_id=${record.ID}`}
            target="_blank"
          >
            <Tooltip title="Print Voucher">
              <PrinterOutlined />
            </Tooltip>
          </NavLink>
        </Space>
      ),
    },
  ];

  const handelDelete = (ID) => {
    setIsDeleted(true);

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: ID,
    };
    const api_config = {
      method: "post",
      url: config.base_url + "Voucher/DeleteVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsDeleted(false);
          message.success(response.data.status_message);
        } else {
          setIsDeleted(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        setIsDeleted(false);
      });
  };
  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);
    SetEndDate(value[1]);
  };
  const disableDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Dated") {
      if (sortOrder == "asc") {
        SetOrderBy(6);
      } else {
        SetOrderBy(5);
      }
    } else if (sortField == "VoucherNo") {
      if (sortOrder == "asc") {
        SetOrderBy(2);
      } else {
        SetOrderBy(1);
      }
    }
  };

  const handelShowModal = (type, record) => {
    setModalType(type);

    const VoucherData = {
      ID: record.ID,
      voucherNo: record.VoucherNo,
      Amount: record.Amount,
      Dated: dayjs(record.Dated),
      Debit_Account_ID: record.Debit_Account?.ID,
      Credit_Account_ID: record.Credit_Account?.ID,
      Description: record.Description,
    };

    setSelectedVoucher(VoucherData);
    setOpen(true);
  };

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
    setButton_Lodding(false);
    form.resetFields();
  };
  const handelVoucher = (formData) => {
    setIsVoucherUpdated(true); // Corrected line
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = dayjs(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      VoucherMode: VoucherMode,
      ...formData,
    };

    if (ModalType === "Edit") {
      data.ID = selectedVoucher.ID;
    }

    const api_config = {
      method: "post",
      url:
        config.base_url +
        (ModalType === "Edit"
          ? "Voucher/UpdateVoucher"
          : "Voucher/AddNewVoucher"),
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code === 1) {
          setIsVoucherUpdated(false);
          setButton_Lodding(false);
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            form.resetFields();
            setConfirmLoading(false);
          }, 2000);
        } else {
          setLoading(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        setLoading(false);
        setButton_Lodding(false);
        messageApi.open({
          type: "error",
          content: "Network Error",
        });
      });
  };

  const onSelectDebitAccount = (item) => {
    setdebitAccountID(item);
    setDebitAccountLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setRecAccount(response.data);
          setDebitAccountLoading(false);
        } else {
          setDebitAccountLoading(false);
        }
      })
      .catch(function (error) {});
  };

  const onSelectCreditAccount = (item) => {
    setcreditAccountID(item);
    setCreditAccountLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          // setlistofAccounts(response.data.listofAccounts);
          setCustomerAccount(response.data);
          setCreditAccountLoading(false);
        } else {
          // Handle error
          setCreditAccountLoading(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {});
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={ModalType == "Edit" ? "Update Voucher" : "Add Expense"}
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={handelVoucher}
          autoComplete="off"
          form={form}
          initialValues={selectedVoucher}
          fields={[
            {
              name: "voucherNo",
              value: selectedVoucher?.voucherNo || voucherNo,
            },
            {
              name: "Amount",
              value: selectedVoucher?.Amount,
            },
            {
              name: "Description",
              value: selectedVoucher?.Description,
            },
            {
              name: "Debit_Account_ID",
              value: selectedVoucher?.Debit_Account_ID, 
            },
            {
              name: "Credit_Account_ID",
              value: selectedVoucher?.Credit_Account_ID, 
            },
          ]}
        >
          <Row gutter={[16, 3]}>
            <Col span={8}>
              <Form.Item
                name="voucherNo"
                label="Voucher No"
                rules={[{ required: true }]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="Amount"
                label="Amount"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Dated" label="Date" rules={[{ required: true }]}>
                <DatePicker allowClear onChange={handleDateChange} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Expense Account"
                name="Debit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  onChange={onSelectDebitAccount}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={ExpenseAccountLoading}
                  options={ExpenseAccount}
                />
              </Form.Item>
              {CreditAccountLoading ? (
                <>
                  <p>
                    <Skeleton.Input style={{ width: 150, height: 40 }} active />
                  </p>
                </>
              ) : (
                <>
                  <p style={{ textAlign: "Left", marginTop: 0 }}>
                    <span className="lbl">Current Balance </span>
                    <span className="val balance">
                      Rs. <strong>{NumberFormat(RecAccount.Balance, 0)}</strong>{" "}
                      {RecAccount.BalanceType}
                    </span>
                  </p>
                </>
              )}
            </Col>
            <Col span={12}>
              <Form.Item
                label="From Account"
                name="Credit_Account_ID"
                className="input-vertical"
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  onChange={onSelectCreditAccount}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  loading={FromAccountLoading}
                  options={FromAccount}
                />
              </Form.Item>
              {FromAccountLoading ? (
                <>
                  <p>
                    <Skeleton.Input style={{ width: 150, height: 40 }} active />
                  </p>
                </>
              ) : (
                <>
                  <p style={{ textAlign: "Left", marginTop: 0 }}>
                    <span className="lbl">Current Balance </span>
                    <span className="val balance">
                      Rs.{" "}
                      <strong>
                        {NumberFormat(CustomerAccount.Balance, 0)}
                      </strong>{" "}
                      {CustomerAccount.BalanceType}
                    </span>
                  </p>
                </>
              )}
            </Col>

            <Col span={24}>
              <Form.Item
                name="Description"
                label="Description"
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    {ModalType == "Edit" ? "Update Expense" : "Add Expense"}
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>
      <LoginChecked />

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h3 className="page-title">Expense Voucher</h3>
            <div className="header-actions">
              <NavLink
                to={`/vouchers/print-voucher-details/?StartDate=${StartDate}&EndDate=${EndDate}&AccountID=${0}&VoucherMode=${VoucherMode}&DebitAccountID=${0}&creditAccountID=${0}`}
                target="blank"
              >
                <Button
                  type="dashed"
                  shape="round"
                  size="large"
                  icon={<PrinterOutlined />}
                >
                  Download / Print
                </Button>
              </NavLink>
              <Button
                type="primary"
                size="large"
                shape="round"
                icon={<PlusOutlined />}
                onClick={() => handelShowModal("Add", {})}
              >
                Expense Voucher
              </Button>
            </div>
          </div>

          <div className="filters-wrap">
            <Form>
              <Form.Item name="">
                <RangePicker
                  style={{
                    width: "100%",
                    marginLeft: 5,
                  }}
                  onChange={handleDateChange}
                  disabledDate={disableDate}
                />
              </Form.Item>
            </Form>
          </div>

          <Table
            size="small"
            columns={columns}
            dataSource={listofVouchers}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
            bordered
            scroll={{
              x: "100%",
            }}
          />

          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={(page) => setPageNo(page)}
              onShowSizeChange={(current, size) => {
                setPageNo(1);
                setPageSize(size);
              }}
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpenseVoucher;
