import React, { useState, useEffect } from "react";
import axios from "axios";
import config from "../../../config";
import { calculateColumnTotal } from "../../Shared/Utility";
import dayjs from "dayjs";
import { Logos } from "../../Shared/Logos";
import { Spin } from "antd";
import { MailOutlined, PhoneOutlined, PrinterOutlined } from "@ant-design/icons";
function PrintDatewiseSummary() {
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const Address = localStorage.getItem("Address");
  const PumpName = localStorage.getItem("PumpName");
  const Email_Address = localStorage.getItem("Email_Address")
  const Mobile_No = localStorage.getItem("Mobile_No")
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [closingStock, setClosingStock] = useState([]);
  const [stockSales, setStockSales] = useState([]);
  const [listOfReading, setListOfReading] = useState([]);
  const [listOfSales, setListOfSale] = useState([]);
  const [paymentReceived, setPaymentReceived] = useState([]);
  const [Expenses, setExpenses] = useState([]);
  const [listOfCashGiven, setListOfCashGiven] = useState([]);

  const urlParams = new URLSearchParams(window.location.search);
  const Dated = urlParams.get("Dated");
  const PumpLogo = localStorage.getItem("pumpLogo");

  const matchingLogo = Logos.find(logo => logo.title.toLowerCase() == PumpLogo.toLowerCase());


  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      Dated: Dated,
    };
    // console.log(data);

    var api_config = {
      method: "post",
      url: config.base_url + "Report/GetDailySummaryReport1",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        // console.log("data", response.data);
        if (response.data.status_code == 1) {
          setData(response.data);
          setClosingStock(response.data.ListofClosingStock);
          setStockSales(response.data.listofStockSales);
          setListOfReading(response.data.listofReading);
          setListOfSale(response.data.ListofProducts);
          setPaymentReceived(response.data.listofCashreceipt);
          setExpenses(response.data.listofExpenses);
          setListOfCashGiven(response.data.listofCashGiven);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin />
        </div>
      ) : (<div className="pprint-body">
        <div className="page">
          <div className="subpage" style={{ padding: "0" }}>
            <div className="report-header">
              <div className="report-header">
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div className="logo">
                          {
                            matchingLogo ? <img
                              src={matchingLogo.src}
                              style={{ width: 50, height: 50 }}
                            /> : <>
                              <p>Logo</p></>
                          }

                          <div className="company">
                            <h3>{PumpName} </h3>
                            <p>{Address} </p>
                          </div>
                        </div>
                      </td>
                      <td>
                        <table className="header-right" style={{ display: 'inline-table' }}>
                          <tbody>
                            <tr>
                              <td>{Mobile_No}</td>
                              <td>
                                <PhoneOutlined />
                              </td>
                            </tr>
                            <tr>
                              <td>{Email_Address}</td>
                              <td>
                                <MailOutlined />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <span id="reportHeader1_lbldated">
                                  Print On {dayjs().format("DD MMM YYYY hh:mm a")}
                                </span>
                              </td>
                              <td>
                                <PrinterOutlined />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h1 className="report-title" style={{ fontSize: "20pt" }}>
                Summary Sheet {dayjs(data.selectedDate).format("D MMMM YYYY")}
              </h1>
              <table className="table-summary">
                <tbody>
                  <tr>
                    <td className="col1" style={{ width: "50%" }}>
                      <div className="report-stock-wrap">
                        {listOfReading.map((item, index) => {
                          return (
                            <>
                              <div className="report-stock-item" key={index}>
                                <h2>{item.ProductCode}</h2>
                                <table>
                                  <tbody>
                                    <tr>
                                      <td>Opening</td>
                                      <td>{item.Current_Reading}</td>
                                    </tr>
                                    <tr>
                                      <td>Closing</td>
                                      <td>{item.Last_Reading}</td>
                                    </tr>
                                    <tr>
                                      <td>Used</td>
                                      <td>
                                        {item.Current_Reading -
                                          item.Last_Reading}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </>
                          );
                        })}
                      </div>
                      <br />
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: "50%" }} valign="top">
                              <table className="report-table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Payments Received</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {paymentReceived.map((item, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{item.Credit_Account?.Name}</td>
                                          <td className="text-right">
                                            {item.Amount}{" "}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Total:</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {calculateColumnTotal(
                                          paymentReceived,
                                          "Amount"
                                        )}
                                      </strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <br />
                              <table className="report-table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">
                                      Payments Received in Banks
                                    </th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                                <tfoot>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Total:</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>00</strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <br />
                              <table className="report-table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Expenses</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {Expenses.map((item, index) => {
                                    return (
                                      <>
                                        <tr key={index}>
                                          <td>{item.Description}</td>
                                          <td className="text-right">
                                            {item.Amount}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Total:</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {calculateColumnTotal(
                                          Expenses,
                                          "Amount"
                                        )}
                                      </strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <br />
                            </td>
                            <td style={{ width: "50%" }} valign="top">
                              <table className="report-table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Cash Paid</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {listOfCashGiven.map((item, index) => {
                                    return (
                                      <>
                                        <tr>
                                          <td>{item.Debit_Account?.Name}</td>
                                          <td className="text-right">
                                            {item.Amount}
                                          </td>
                                        </tr>
                                      </>
                                    );
                                  })}
                                </tbody>
                                <tfoot>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Total:</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>
                                        {calculateColumnTotal(
                                          listOfCashGiven,
                                          "Amount"
                                        )}
                                      </strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                              <br />
                              <table className="report-table">
                                <thead>
                                  <tr>
                                    <th colSpan="2">Cash Paid Through Banks</th>
                                  </tr>
                                </thead>
                                <tbody></tbody>
                                <tfoot>
                                  <tr>
                                    <td style={{ textAlign: "right" }}>
                                      <strong>Total:</strong>
                                    </td>
                                    <td className="text-right">
                                      <strong>00</strong>
                                    </td>
                                  </tr>
                                </tfoot>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br />
                      <table className="report-table">
                        <thead>
                          <tr>
                            <th colSpan="2">Sales Detail</th>
                          </tr>
                          <tr>
                            <th>Sr. #</th>

                            <th>Customer</th>
                            <th>Product</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {listOfSales.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>

                                  <td>{item.Account_BE?.Name}</td>
                                  <td>{item.Product_BE?.ProductCode}</td>
                                  <td className="text-right">{item.Price}</td>
                                  <td className="text-right">
                                    {item.Quantity}
                                  </td>
                                  <td className="text-right">
                                    {item.Amount.toFixed(2)}
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td style={{ textAlign: "right" }} colSpan="5">
                              <strong>Total:</strong>
                            </td>
                            <td className="text-right">
                              <strong>
                                {calculateColumnTotal(
                                  listOfSales,
                                  "Amount"
                                ).toFixed(2)}
                              </strong>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </td>
                    <td className="col2">
                      <table className="report-table">
                        <thead>
                          <tr>
                            <th colSpan="2">Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Opening Cash </td>
                            <td className="text-right">{data.openingCash}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>+</td>
                          </tr>
                          <tr>
                            <td>Cash Sales</td>
                            <td className="text-right">{data.cashSales}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>+ </td>
                          </tr>
                          <tr>
                            <td>Payment Received </td>
                            <td className="text-right">{data.TotalPayments}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>= </td>
                          </tr>
                          <tr>
                            <td>Total Inwards </td>
                            <td className="text-right">{data.TotalInwards}</td>
                          </tr>

                          <tr>
                            <td>Cash Purchases </td>
                            <td className="text-right">{data.cashPurchase}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>+ </td>
                          </tr>
                          <tr>
                            <td>Cash Paid </td>
                            <td className="text-right">{data.cashGiven}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>+ </td>
                          </tr>
                          <tr>
                            <td>Expenses Paid </td>
                            <td className="text-right">{data.expensesPaid}</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>=</td>
                          </tr>
                          <tr>
                            <td>Total Paid</td>
                            <td className="text-right">
                              {data.cashGiven + data.expensesPaid}
                            </td>
                          </tr>

                          <tr>
                            <td>Closing Cash</td>
                            <td className="text-right">{data.closingCash}</td>
                          </tr>
                        </tbody>
                      </table>

                      <table className="report-table">
                        <thead>
                          <tr>
                            <th colSpan="4">Stock Sales</th>
                          </tr>
                          <tr>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Price</th>
                            <th>Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stockSales != null &&
                            stockSales.length > 0 &&
                            stockSales.map((item, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{item.Product_BE?.Name}</td>
                                    <td>{item.Quantity}</td>
                                    <td>{item.Price}</td>
                                    <td>{item.Amount.toFixed(4)}</td>
                                  </tr>
                                </>
                              );
                            })}

                          <tr>
                            <td colSpan="3">
                              <strong>Total</strong>
                            </td>
                            <td>
                              {stockSales &&
                                calculateColumnTotal(
                                  stockSales,
                                  "Amount"
                                ).toFixed(4)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <strong>Credit Sales</strong>
                            </td>
                            <td>
                              {data.CreditSales
                                ? data.CreditSales.toFixed(4)
                                : null}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="report-table">
                        <thead>
                          <tr>
                            <th colSpan="5">Close Stock</th>
                          </tr>
                          <tr>
                            <th>Sr. No.</th>
                            <th>Code</th>
                            <th>Product</th>
                            <th>Quantity</th>
                            <th>Unit</th>
                          </tr>
                        </thead>
                        <tbody>
                          {closingStock.map((item, index) => {
                            return (
                              <>
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.ProductCode} </td>
                                  <td>{item.Name}</td>
                                  <td>{item.Balance}</td>
                                  <td>{item.Measure_Unit_BE?.Name}</td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>)}

    </>
  );
}

export default PrintDatewiseSummary;
