import React from "react";
import { Skeleton } from "antd";

function BoxCard({ title, amount, icon, prefix, suffix, loading }) {
  return (
    
      loading ? <Skeleton.Input
      style={{ width: "230px", height: "90px", borderRadius:"10px" }} 
      active
    />
        :
        <div className="box-card-content">
          <div className="sub-content">
            <p>{title}</p>
            <h3>{prefix} {amount} {suffix} </h3>
          </div>
          <div className="icon">
            {icon}
          </div>
        </div>
    

  );
}

export default BoxCard;
