import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../../config";
import logo from "../../assets/images/Logo/logo-white.svg";






import { Link, NavLink, useNavigate } from "react-router-dom";
import { Button, message, Form, Input, Spin } from "antd";
import { KeyOutlined, LockOutlined, MailOutlined } from "@ant-design/icons";
import dayjs from "dayjs";

function Login() {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const AccessKey = localStorage.getItem("AccessKey");
  const ID = localStorage.getItem("ID");
  const [pageLoaded, setPageLoaded] = useState(false); // New state to track page loading

  

  let navigate = useNavigate();

  const handleSubmit = (formData) => {
    setLoading(true);
    var api_config = {
      method: "post",
      url: config.base_url + "Members/SignIn",
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios(api_config)
      .then(function (response) {
        //console.log(response.data);
        if (response.data.status_code == 1) {

            localStorage.setItem("AccessKey", response.data.AccessKey);
            localStorage.setItem("ID", response.data.ID);
            
            let today = dayjs().format("YYYY-MM-DD");
            let expiry = dayjs(response.data.PackageExpiry).format("YYYY-MM-DD");

          if (expiry < today) {
            navigate("/package-expired")
          }
          else {
            
            localStorage.setItem("Email_Address", response.data.Email_Address);
            localStorage.setItem("Full_Name", response.data.Full_Name);
            
            localStorage.setItem("Member_Type", response.data.Member_Type);
            localStorage.setItem("Mobile_No", response.data.Mobile_No);
            localStorage.setItem("PackageExpiry", response.data.PackageExpiry);
            localStorage.setItem("PackageName", response.data.PackageName);
            localStorage.setItem("PumpName", response.data.PumpName);
            localStorage.setItem("pumpCode", response.data.pumpCode);
            localStorage.setItem("pumpLogo", response.data.pumpLogo);
            localStorage.setItem("Address", response.data.Address);

            
            navigate("/dashboard/analytics");
          }

          setLoading(false);
          //
        } else {
          setLoading(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    document.title = "Fuelbook - Login to Your Account"
    setTimeout(() => {
      setPageLoaded(true);
    }, 2000); // Simulating a 2 second delay for page loading
  }, []);

  return (
    <>
      {contextHolder}
      {!pageLoaded ? ( // Render loader until the page is loaded
        <div className="loader-container" style={{width:"100%",height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
          <Spin size="large" />
        </div>
      ) : (
        <div className="auth-wrap">
          <div className="auth-head">
            <div className="logo">
              <img src={logo} alt="" />
            </div>
            <p>Welcome</p>
            <h1>Login</h1>
          </div>
          <div className="auth-card">
            <Form
              name="auth-form"
              className="auth-form"
              size="large"
              layout="vertical"
              onFinish={handleSubmit}
            >
              <Form.Item
                name="PumpCode"
                label="Pump Code"
                rules={[
                  {
                    required: true,
                    message: "Please enter your pump code!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <KeyOutlined />
                  }
                  placeholder="Pump Code"
                />
              </Form.Item>

              <Form.Item
                name="User_Name"
                label="Email Address"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please enter email address",
                  },
                ]}
              >
                <Input
                  prefix={
                    <MailOutlined />
                  }
                  placeholder="Enter email address"
                  type="email"
                />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your Password!",
                  },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined />
                  }
                  type="password"
                  placeholder="Password"
                />
              </Form.Item>
              <div className="text-end" style={{ marginBottom: "20px" }}>
                <Link to="/forgot-password" className="link">Forgot Password or Pump code?</Link>
              </div>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={loading}
                  shape="round"
                >
                  Log in
                </Button>


              </Form.Item>
            </Form>
            <p className="text-center" style={{ marginTop: "20px" }}>
              Don't have an account? <br />
              <Link to="/sign-up">Register now!</Link>
            </p>
          </div>

        </div>
       )}
    </>
  );
}

export default Login;
