import React, { useState } from "react";
import dizzleNozzle from "../../assets/images/dissel.nozzle.svg";
import dizzleNozzle2 from "../../assets/images/dieselnozzle2.svg";

import { Row, Col } from "antd";
import { CheckCircleIcon } from "@heroicons/react/16/solid";

function SelectNozzles() {
  return (
    <>
      <div className=" right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">Add Nozzles</h6>
          </div>
          <div className="nozzle-text">
            <p>
              Select your petrol and diesel nozzles from the options below to
              proceed
            </p>
          </div>
          <div className="nozzle-text">
            <h6>Diesel Nozzles</h6>
          </div>
          <Row gutter={[24, 24]}>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>01</span>
                <CheckCircleIcon />
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>02</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>03</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>04</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>05</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle} alt="" />
                </div>
                <span>06</span>
              </div>
            </Col>
          </Row>
          <div className="nozzle-text">
            <h6>Petrol Nozzles</h6>
          </div>
          <Row gutter={[24, 24]}>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>01</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>02</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>03</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>04</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>05</span>
              </div>
            </Col>
            <Col md={4}>
              <div className="diesel-nozzles">
                <div className="diesel-img">
                  <img src={dizzleNozzle2} alt="" />
                </div>
                <span>06</span>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SelectNozzles;
