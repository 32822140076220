import React, { useState, useEffect } from "react";
import {
  Button,
  Checkbox,
  Col,
  Select,
  Form,
  Input,
  Row,
  DatePicker,
  Tooltip,
  message,
} from "antd";
import axios from "axios";
import config from "../../config";
import ProductsDropDown from "../Shared/ProductsDropDown";
import AccountDropDown from "../Shared/AccountDropDown";
import LoginChecked from "../Shared/LoginChecked";
import moment from "moment";
import {
  DeleteOutlined,
  PrinterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { NavLink,useNavigate } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import { LockClosedIcon, LockOpenIcon } from "@heroicons/react/24/outline";

function AddPurchase() {
  const [form] = Form.useForm();

  // const purchase_id = urlParams.get('purchase_id');
  const [messageApi, contextHolder] = message.useMessage();
  const accountTypesArray = AccountDropDown();
  const productTypesArray = ProductsDropDown();
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [purchaseList, setPurchaseList] = useState([]);
  const [Quantity, setQuantity] = useState(0);
  const [Price, setPrice] = useState(0);
  const [ProductName, setProductName] = useState("");
  const [AccountName, setAccountName] = useState("");
  const [Dated, setDated] = useState(moment().format("YYYY-MM-DD"));
  const [Description, setDescription] = useState("");
  const [Reference_No, setReferenceNo] = useState("");
  const [voucherNo, setVoucherNo] = useState("");
  const [loading, setLoading] = useState(false);

  const [loadingSave, setLoadingSave] = useState(false);

  const navigate = useNavigate();


  const [QtyLocked, setQtyLocked] = useState(false);
  const [PriceLocked, setPriceLocked] = useState(false);

  const [totals, setTotals] = useState({
    cashSaleTotal: 0,
    creditSaleTotal: 0,
    totalPrice: 0,
  });

  const [TestPrice, setTestPrice] = useState("");

  const [invoiceSaved, setInvoiceSaved] = useState(false);

  // loadings
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [ListOfAccounts, setListOfAccounts] = useState([]);
  const [priceGroup, setpriceGroup] = useState([]);

  useEffect(() => {
    document.title = "Add Purchase";
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      // ID: purchase_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/GetNextPurchaseInvoiceNo",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.status_code == 1) {
          setVoucherNo(response.data.InvoiceNo);
          // Handle success
          setLoading(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoadingAccounts(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      // ID: purchase_id,
    };
    var api_config = {
      method: "post",
      url: config.base_url + "Account/GetAccountForPurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data);
        if (response.data.status_code == 1) {
          let list = response.data.listofAccounts;

          if (list) {
            const result = list.map((item) => ({
              value: item.ID,
              label: item.Name,
            }));

            setListOfAccounts(result);
          }

          setLoadingAccounts(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const handleSubmit = (formData) => {
   // formData["Is_Cash"] = formData["Is_Cash"] == true ? false : true;
    formData["Vehicle_No"] = formData["Vehicle_No"] == undefined ? "" : formData["Vehicle_No"];

    const dataa = {
      ProductName: ProductName,
      AccountName: AccountName,
      ...formData,
    };

    setPurchaseList([...purchaseList, dataa]);

    form.resetFields();
    setQtyLocked(false);  
    setPriceLocked(false);
    setQuantity(0);
    setPrice(0);
  };

  function handleCheckboxChange(index) {
    setPurchaseList((prevData) => {
      const newData = [...prevData];
      newData[index].Is_Cash = !newData[index].Is_Cash;
      return newData;
    });
  }

  useEffect(() => {
    let cashTotal = 0;
    let creditTotal = 0;
    let TotalPetrolPurchase = 0;
    let TotalDieselPurchase = 0;

    purchaseList.forEach((item) => {
      if (item.Is_Cash == true) {
        cashTotal += parseInt(item.total); // Use parseInt to convert string to number
      } else {
        creditTotal += parseInt(item.total);
      }

      if (item.ProductName.toLowerCase().includes("petrol")) {
        TotalPetrolPurchase += parseInt(item.total);
      } else if (item.ProductName.toLowerCase().includes("diesel")) {
        TotalDieselPurchase += parseInt(item.total);
      }
    });

  }, [purchaseList]);

  useEffect(() => {
    // Convert Price and Quantity to numbers and calculate the total whenever they change
    const priceValue = parseFloat(Price);
    const quantityValue = parseFloat(Quantity);
    const totalValue = priceValue * quantityValue;
    const total = isNaN(totalValue) ? "" : totalValue.toString();
    form.setFieldsValue({
      total: total,
    });
  }, [Price, Quantity]);

  const handleQuantity = (e) => {
    const quantity = e.target.value;
    setQuantity(quantity);

    if (!isNaN(parseFloat(Price))) {
      const totalValue = parseFloat(Price) * parseFloat(quantity);
      const total = isNaN(totalValue) ? "" : totalValue.toString();
      form.setFieldsValue({
        Total: total,
      });
    }
  };

  const handlePrice = (e) => {
    const price = e.target.value;
    setPrice(price);

    if (!isNaN(parseFloat(Quantity))) {
      const totalValue = parseFloat(Quantity) * parseFloat(price);
      const total = isNaN(totalValue) ? "" : totalValue.toString();
      form.setFieldsValue({
        Total: total,
      });
    }
  };

  const handleTotalChange = () => {
    const total = form.getFieldValue("Total");
    const price = form.getFieldValue("Price");
    const qty = form.getFieldValue("Quantity");

    if(QtyLocked) {
      const newprice = total / qty;
      form.setFieldsValue({ Price: newprice.toFixed(2) });
    }
    else if (total && price) {
      const quantity = total / price;
      form.setFieldsValue({ Quantity: quantity.toFixed(2) });
    }
  };

  const handleProductChange = (value, option) => {
    console.log(option.price);
    setProductName(option.label);
    setTestPrice(option.TestPrice);
    form.setFieldValue("Price", option.price);
    setPrice(option.price);
  };
  const handleAccountChange = (value, option) => {
    setAccountName(option.label);
  };

  const handleSaveInvoice = () => {
    if (Dated == null) {
      console.log(Dated);
      message.error("Please Select Date");
      return;
    }

    if(purchaseList.length == 0) {
      message.error("Please add cash details");
      return false;
    }


    setLoadingSave(true);

    const modifiedData = purchaseList.map(
      ({ AccountName, ProductName, ...rest }) => rest
    );

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      Dated: Dated,
      Description,
      Reference_No,
      ListofPurchaseDetails: modifiedData,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Purchase/AddPurchase",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          // setInvoiceNo(response.data.InvoiceNo);
          // Handle success
          setInvoiceSaved(true);
          setLoadingSave(false);
          navigate(`/purchase/manage-purchase`)
        } else {
          // Handle error
          setLoadingSave(false);
          message.error(response.data.status_message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleDelete = (index) => {
    const updatedItems = [...purchaseList];
    // Remove the item at the specified index
    updatedItems.splice(index, 1);
    // Update the state with the new array
    setPurchaseList(updatedItems);
  };

  useEffect(() => {
    const groupedData = purchaseList.reduce((acc, obj) => {
      const key = obj.Product_ID;
      if (!acc[key]) {
        acc[key] = {
          Product_ID: obj.Product_ID,
          ProductName: obj.ProductName,
          Total_Price: 0,
          Quantity: 0, // Start with 0 quantity
        };
      }
      // Update both Total_Price and Quantity
      acc[key].Total_Price += parseFloat(obj.Quantity) * parseFloat(obj.Price);
      acc[key].Quantity += parseFloat(obj.Quantity); // Accumulate the quantity
      return acc;
    }, {});
  
    setpriceGroup(Object.values(groupedData));
  }, [purchaseList]);

  useEffect(() => {
    const cashSaleTotal = purchaseList.reduce((acc, obj) => {
      if (obj.Is_Cash) {
        return acc + parseFloat(obj.Total);
      }
      return acc;
    }, 0);

    const creditSaleTotal = purchaseList.reduce((acc, obj) => {
      if (!obj.Is_Cash) {
        return acc + parseFloat(obj.Total);
      }
      return acc;
    }, 0);

    const totalPrice = purchaseList.reduce((acc, obj) => {
      return acc + parseFloat(obj.Total);
    }, 0);

   

    setTotals({
      cashSaleTotal,
      creditSaleTotal,
      totalPrice,
    });
  }, [purchaseList]);

  const disabledDate = (current) => {
    return current && current > moment().endOf('day');
  };


  const handleQtyLock = () => {
    setQtyLocked(true);
    setPriceLocked(false);
  }

  const handleQtyUnlock = () => {
    setQtyLocked(false);
  }


  const handlePriceLock = () => {
    setPriceLocked(true);
    setQtyLocked(false);
  }

  const handlePriceUnlock = () => {
    setPriceLocked(false);
  }

  return (
    <>
      <LoginChecked />
      {contextHolder}

      <div className="right-side-contents">
        <div className="page-content">
          <div className="page-header">
            <h6 className="page-title">
              <NavLink to="/purchase/manage-purchase">
                <ChevronLeftIcon />
              </NavLink>
              Add Purchase
            </h6>
          </div>

          <Row gutter={[10, 20]}>
            <Form
              name="basic"
              autoComplete="off"
              fields={[
                {
                  name: "InvoiceNo",
                  value: voucherNo,
                },
              ]}
            >
              <Row gutter={24}>
                <Col md={4} xs={8}>
                  <Form.Item
                    className="input-vertical"
                    label="Invoice No"
                    name="InvoiceNo"
                    rules={[
                      {
                        required: true,
                        message: "Enter invoice number",
                      },
                    ]}
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    className="input-vertical"
                    label="Dated"
                    name="Dated"
                  >
                    <DatePicker
                      onChange={(date, dateString) => {
                        setDated(dateString);
                        console.log(dateString);
                        
                      }}
                      disabledDate={disabledDate}
                      
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={6}>
                  <Form.Item
                    className="input-vertical"
                    label="Ref No"
                    name="Reference_No"
                  >
                    <Input onChange={(e) => setReferenceNo(e.target.value)} />
                  </Form.Item>
                </Col>
                <Col md={10} xs={18}>
                  <Form.Item
                    className="input-vertical"
                    label="Description"
                    name="Description"
                  >
                    <Input onChange={(e) => setDescription(e.target.value)} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Row>

          <Form
            name="basic"
            layout="horizontal"
            form={form}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <div className="table-responsive">
              <table className="add-sale-table">
                <thead>
                  <tr>
                    <th>SR</th>
                    <th>Product</th>
                    <th>Account</th>
                    <th>Vehicle No</th>
                    <th>
                      <div style={{display: "flex", alignItems: "center", gap: 4}}>
                      Quantity 
                      {
                        QtyLocked ?
                        <LockClosedIcon onClick={handleQtyUnlock} style={{width: 16}} /> 
                        
                        :
                        <LockOpenIcon onClick={handleQtyLock} style={{width: 16}} />
                      }
                      
                      </div>
                    </th>
                    <th>
                    <div style={{display: "flex", alignItems: "center", gap: 4}}>
                      Price 
                      {
                        PriceLocked ?
                        <LockClosedIcon onClick={handlePriceUnlock} style={{width: 16}} />
                        :
                        <LockOpenIcon onClick={handlePriceLock} style={{width: 16}} />  
                      }
                      
                      </div>
                    </th>
                    <th>Total</th>
                    <th>Cash</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseList.map((item, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item.ProductName}</td>
                      <td>{item.AccountName}</td>
                      <td>{item.Vehicle_No}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.Price}</td>
                      <td>{item.Price * item.Quantity}</td>
                      <td>
                        <Checkbox
                          checked={item.Is_Cash}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>

                      <td>
                        <DeleteOutlined onClick={(e) => handleDelete(index)} />
                      </td>
                    </tr>
                  ))}
                  <tr className="table-form">
                    <td></td>
                    <td>
                      <Form.Item
                        name="Product_ID"
                        rules={[
                          {
                            required: true,
                            message: "Select product",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Select Product"
                          loading={productTypesArray === false ? true : false}
                          options={productTypesArray}
                          optionFilterProp="label" // Specify the property to be used for filtering
                          filterOption={(input, option) =>
                            option.label
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          onChange={handleProductChange}
                          style={{ width: "200px" }}
                        />
                      </Form.Item>
                    </td>

                    <td>
                      <Form.Item
                        name="Account_ID"
                        rules={[
                          {
                            required: true,
                            message: "Please select an account!",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          placeholder="Account"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.label.toLowerCase().includes(input.toLowerCase())
                          }
                          onChange={handleAccountChange}
                          loading={loadingAccounts}
                          options={ListOfAccounts}
                          style={{ width: "150px" }}
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Vehicle_No">
                        <Input placeholder="Vehicle Number" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="Quantity"
                        
                        rules={[
                          {
                            required: true,
                            message: "Please enter quantity!",
                          },
                        ]}
                      >
                        <Input onChange={handleQuantity} placeholder="Qty" disabled={QtyLocked} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="Price"
                        rules={[
                          {
                            required: true,
                            message: "Please enter price!",
                          },
                        ]}
                      >
                        <Input onChange={handlePrice} placeholder="Price" disabled={PriceLocked} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Total">
                        <Input
                          onChange={handleTotalChange}
                          placeholder="Total"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item name="Is_Cash">
                        <Checkbox>Cash</Checkbox>
                      </Form.Item>
                    </td>
                    <td>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="small"
                        shape="round"
                        loading={loading}
                      >
                        Add
                      </Button>
                    </td>
                  </tr>

                  {totals.totalPrice > 0 && (
                    <>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Sale
                        </td>
                        <td colSpan={3}>
                          <strong>{totals.totalPrice}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Cash Sale
                        </td>
                        <td colSpan={3}>{totals.cashSaleTotal}</td>
                      </tr>
                      <tr>
                        <td colSpan={6} style={{ textAlign: "right" }}>
                          Total Credit Sale
                        </td>
                        <td colSpan={3}>{totals.creditSaleTotal}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Form>

          <ul className="total-price">
            {priceGroup.map((item) => (
              <li key={item.Product_ID}>
                <p>{item.ProductName}</p>
                <h4>Qty: {item.Quantity}</h4>
                <h4>Rs. {item.Total_Price}</h4>
              </li>
            ))}
          </ul>

          <div
            style={{
              justifyContent: "flex-end",
              marginTop: "30px",
              display: "flex",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleSaveInvoice}
              type="primary"
              size="large"
              shape="round"
              loading={loadingSave}
              disabled={purchaseList.length == 0 || !Dated}
            >
              Save Invoice
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPurchase;
