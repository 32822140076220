import React from "react";
import {
  Text,
  View,
  Image,
} from "@react-pdf/renderer";
import { Logos } from "../components/Shared/Logos";

import dayjs from "dayjs";
import pdfStyles from './pdfStyles';
// import print from "../../../assets/images/print.png";
import print from "../assets/images/print.png";
import email from "../assets/images/email.png";
import phone from "../assets/images/phone.png";

function PrintHeaderAccount() {

  const LogoPumpName = localStorage.getItem("PumpName");
  const Email_Address = localStorage.getItem("Email_Address");
  const Mobile_No = localStorage.getItem("Mobile_No");
  const Address = localStorage.getItem("Address");
  const PumpLogo = localStorage.getItem("pumpLogo");

  const matchingLogo = Logos.find(logo => logo.title.toLowerCase() == PumpLogo.toLowerCase());



  return (
    <>
      <View style={pdfStyles.header} fixed>
        {matchingLogo ? (
          <Image source={matchingLogo.src} style={{ width: 50, height: 50 }} />
        ) : (
          <Text>Logo</Text>
        )}
        <View style={pdfStyles.logoside}>
          <Text style={pdfStyles.headerinfo} >
            {LogoPumpName}
            </Text>
          <Text style={pdfStyles.tdValue}>{Address}</Text>
        </View>
        <View style={pdfStyles.headercontent}>
          <View style={pdfStyles.contentwrap}>
            <Text style={pdfStyles.contenttitle}>{Email_Address}</Text>
            <Image source={email} style={pdfStyles.contentimg} />
          </View>
          <View style={pdfStyles.contentwrap}>
            <Text style={pdfStyles.contenttitle}>{Mobile_No}</Text>
            <Image source={phone} style={pdfStyles.contentimg} />
          </View>
          <View style={pdfStyles.contentwrap}>
            <Text style={pdfStyles.contenttitle}>Print On {dayjs().format("DD MMM YYYY hh:mm a")}</Text>
            <Image source={print} style={pdfStyles.contentimg} />
          </View>
        </View>
      </View>
    </>
  );
}

export default PrintHeaderAccount;
