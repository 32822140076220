import React, { useEffect, useState } from "react";
import account from "../../../assets/images/account.png";
import balance from "../../../assets/images/balance.png";
import calander from "../../../assets/images/calander.png";
import { NumberFormat } from "../../Shared/Utility";
import {
  Button,
  Col,
  Spin,
  Row,
  Space,
  Table,
  DatePicker,
  Form,
  Dropdown,
  message,
  Modal,
  Input,
  Select,
  Pagination,
  Tooltip,
  Divider,
} from "antd";
import LoginChecked from "../../Shared/LoginChecked";
import axios from "axios";
import config from "../../../config";
import { NavLink, useParams } from "react-router-dom";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";
import moment from "moment";
import {
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PrinterOutlined,
  DownOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import AccountDropDown from "../../Shared/AccountDropDown";
import dayjs from "dayjs";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

function AccountLedger() {
  const urlParams = new URLSearchParams(window.location.search);

  const account_id = urlParams.get("account_id");
  const Name = urlParams.get("Name");
  const ID = urlParams.get("ID");

  const { RangePicker } = DatePicker;
  const [OrderBy, SetOrderBy] = useState("1");
  const [Dated, setDated] = useState(null);
  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [listofAccounts, SetlistofAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [listofAccount, setlistofAccount] = useState([]);
  const [IsVoucherUpdated, setIsVoucherUpdated] = useState(false);
  const [AccountID, SetAccountID] = useState(0);
  const [VoucherType, SetVoucherType] = useState("");
  const [DebitAccountID, SetDebitAccountID] = useState(0);
  const [creditAccountID, SetCreditAccountID] = useState(0);
  const [Button_Lodding, setButton_Lodding] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [open, setOpen] = useState(false);
  const accounts = AccountDropDown();
  const [DebitCount, setDebitCount] = useState("");
  const [CreditCount, setCreditCount] = useState("");
  const [Total, setTotal] = useState("");
  const [listofVouchers, SetlistofVouchers] = useState([]);
  const [TotalVouchers, setTotalVouchers] = useState("");
  const [debitAccountID, setdebitAccountID] = useState(0);
  const [loadingRecAccount, setLoadingRecAccount] = useState(false);
  const [RecAccount, setRecAccount] = useState({});
  const [AccountHolder, setAccountHolder] = useState("");
  const [AccountIdData, setAccountIdData] = useState("");

  const [StartDate, SetStartDate] = useState("");
  const [EndDate, SetEndDate] = useState("");

  // pagination
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalRecords, setTotalRecords] = useState(0);

  let params = useParams();

  useEffect(() => {
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      PageNo: pageNo,
      PageSize: pageSize,
      StartDate: StartDate,
      EndDate: EndDate,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountLedger",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        console.log('get account ledger',response.data)
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofAccounts(response.data.listofLedger);
          setTotalRecords(response.data.totalRecords);

          setLoading(false);
        } else {
          setLoading(false);
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate, pageSize, pageNo, IsVoucherUpdated]);

  useEffect(() => {
    document.title = "Account Ledger";
    setIsVoucherUpdated(false);
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      StartDate: StartDate,
      EndDate: EndDate,
      OrderBy: OrderBy,
      PageNo: pageNo,
      PageSize: pageSize,
      AccountID: AccountID,
      VoucherType: VoucherType,
      Debit_Account_ID: DebitAccountID,
      Credit_Account_ID: creditAccountID,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVouchers",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success
          SetlistofVouchers(response.data.listofVouchers);
          setTotalVouchers(response.data.TotalVouchers);
          setDebitCount(response.data.DebitCount);
          setCreditCount(response.data.CreditCount);
          setTotal(response.data.Total);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [StartDate, EndDate]);

  useEffect(() => {
    setLoadingAccounts(false);

    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      OrderBy: 1,
      PageNo: 1,
      PageSize: 10000,
      BalanceType: "",
      Account_Type_ID: "",
      Dated: Dated,
      Name: "",
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccounts",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          // Handle success

          let list = response.data.listofAccounts;
          const result = list.map((item) => ({
            value: item.ID,
            label: item.Name,
          }));

          setlistofAccount(result);

          setLoadingAccounts(false);
        } else {
          // Handle error
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect((item) => {
    setdebitAccountID(item);
    setLoadingRecAccount(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: item,
    };
   
    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        console.log(response.data)
        if (response.data.status_code == 1) {

          setRecAccount(response.data);
          setLoadingRecAccount(false);
          setLoading(false);
        } else {
          // Handle error
          // setLoadingRecAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: account_id,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "account/GetAccountbyIDWithBalance",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
      
        if (response.data.status_code == 1) {
          setLoading(false);
          setAccountIdData(response.data);
        } else {
          // setLoadingGetAccount(false);
        }
        //   setLoading(false);
      })
      .catch(function (error) {
        // console.log(error);
      });
  }, []);

  const columns = [
    {
      title: "Sr.# ",
      dataIndex: "sr",
      key: "sr",
      render: (_, record, index) =>
        record.ID ? index + 1 + pageSize * (pageNo - 1) : null,
    },
    {
      title: "Dated",
      dataIndex: "Transaction_Date",
      key: "Transaction_Date",
      render: (transactionDate) => {
        const formattedDate = transactionDate
          ? moment(transactionDate).format(config.date_format)
          : moment().format(config.date_format);
        return <span>{formattedDate}</span>;
      },
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
    },
    {
      title: " Vehicle No	",
      dataIndex: "Vehicle_No",
      key: "Vehicle_No",
    },
    {
      title: "Receipt No",
      dataIndex: "Receipt_No",
      key: "Receipt_No",
    },
    {
      title: "Debit",
      dataIndex: "Debit",
      key: "Debit",
      align: "right",
      render:(_,record)=>{
        return NumberFormat(record.Debit,0)
      }
    },
    {
      title: "Credit",
      dataIndex: "Credit",
      key: "Credit",
      align: "right",
      render:(_,record)=>{
        return NumberFormat(record.Credit,0)
      }
    },
    {
      title: "Balance	",
      dataIndex: "Balance",
      key: "Balance",
      align: "right",
      render:(_,record)=>{
        return NumberFormat(record.Balance,0)
      }
      
    },
    {
      title: "Balance Type",
      dataIndex: "BalanceType",
      key: "BalanceType",
    },
    {
      title: "Document Type",
      dataIndex: "Reference_Type",
      key: "Reference_Type",
    },
    {
      title: "Ref.#",
      dataIndex: "Reference_No",
      key: "Reference_No",
    },
    {
      title: "Action",
      key: "action",
      fixed:"right",
      render: (_, record) => (
        <Space size="middle">
          <Tooltip title="Edit">
            {record.Reference_Type === "Voucher" && (
              <EditOutlined onClick={() => ShowModal(record.Reference_No)} />
            )}
            {record.Reference_Type === "Sale" && (
              <NavLink to={`/sales/edit-sales/${record.Reference_No}`}>
                <EditOutlined />
              </NavLink>
            )}
            {record.Reference_Type === "Purchase" && (
              <NavLink to={`/purchase/edit-purchase/${record.Reference_No}`}>
                <EditOutlined />
              </NavLink>
            )}
          </Tooltip>

          <a>
            {record.Reference_Type === "Voucher" && (
              <NavLink
                to={`/accounts/print-voucher-list/?voucher_id=${record.Reference_No}`}
                target="_blank"
              >
                <Tooltip title="Print Voucher">
                  <PrinterOutlined />
                </Tooltip>
              </NavLink>
            )}

            {record.Reference_Type === "Sale" && (
              <NavLink
                to={`/sales/printnewsale/${record.Reference_No}`}
                target="_blank"
              >
                <Tooltip title="Print Sale">
                <FileOutlined />
                </Tooltip>
              </NavLink>
            )}

            {record.Reference_Type === "Purchase" && null}

            {/* <FileOutlined /> */}
          </a>
        </Space>
      ),
    },
  ];

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&PrintType=All&StartDate=${StartDate}&EndDate=${EndDate}`}
        >
          Print
        </a>
      ),
      icon: <PrinterOutlined />,
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&Name=${Name}&PrintType=Voucher&TitleType=Sales&StartDate=${StartDate}&EndDate=${EndDate}`}
        >
          Print as Invoice
        </a>
      ),
      icon: <FileOutlined />,
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/accounts/print-account-ledger/?account_id=${account_id}&Name=${Name}&PrintType=Sale&StartDate=${StartDate}&EndDate=${EndDate}`}
        >
          Print Only Sales
        </a>
      ),
      icon: <PrinterOutlined />,
    },
    {
      key: "4",
      label: (
        <a target="_blank" rel="noopener noreferrer" href="#">
          Email to client
        </a>
      ),
      icon: <MailOutlined />,
    },
    // {
    //   key: "5",
    //   label: (
    //     <a target="_blank" rel="noopener noreferrer" href="#">
    //       Adjust Ledger
    //     </a>
    //   ),
    //   icon: <SettingOutlined />,
    // },
  ];

  const onPageChange = (page, pageSize) => {
    setPageNo(page);
  };

  const onPageSizeChange = (current, size) => {
    setPageNo(1); // Reset to the first page when changing page size
    setPageSize(size);
  };

  const handleTableChange = async (pagination, filters, sorter) => {
    // Make API request with sorting parameters
    const sortField = sorter.field;
    const sortOrder = sorter.order === "descend" ? "desc" : "asc";

    if (sortField == "Name") {
      if (sortOrder == "asc") {
        SetOrderBy(1);
      } else {
        SetOrderBy(2);
      }
    } else if (sortField == "Balance") {
      if (sortOrder == "asc") {
        SetOrderBy(3);
      } else {
        SetOrderBy(4);
      }
    }
  };
  const Update_Voucher = (formData) => {
    setButton_Lodding(true);
    setLoading(true);

    formData["Dated"] = moment(formData["Dated"]).format("YYYY-MM-DD");

    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ID: selectedVoucher.ID,
      ...formData,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/UpdateVoucher",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          setIsVoucherUpdated(true);
          setButton_Lodding(false);
          setLoading(false);
          messageApi.open({
            type: "success",
            content: response.data.status_message,
          });

          setConfirmLoading(true);
          setTimeout(() => {
            setOpen(false);
            setConfirmLoading(false);
          }, 1000);
        } else {
          // Handle error
          setLoading(false);
          setButton_Lodding(false);
          messageApi.open({
            type: "error",
            content: response.data.status_message,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ShowModal = (RefId) => {
    const data = {
      UserID: UserID,
      AccessKey: AccessKey,
      ID: RefId,
    };

    var api_config = {
      method: "post",
      url: config.base_url + "Voucher/GetVoucherByID",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(api_config)
      .then(function (response) {
        if (response.data.status_code == 1) {
          const record = response.data;

          const editVoucher = {
            ID: record.ID,
            voucherNo: record.VoucherNo,
            Amount: record.Amount,
            Dated: dayjs(record.Dated),
            Debit_Account_ID: record.Debit_Account_ID,
            Credit_Account_ID: record.Credit_Account_ID,
            Description: record.Description,
          };

          setSelectedVoucher(editVoucher);
          setOpen(true);

          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    // datafetch();

    // record = response.data.vouvher;
  };

  const handleCancel = () => {
    setSelectedVoucher(null);
    setOpen(false);
  };
  const handleDateChange = (date, value) => {
    SetStartDate(value[0]);

    SetEndDate(value[1]);
  };

  const handleDate = (date, value) => {
    setDated(value);
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  return (
    <>
      <LoginChecked />
      <Modal
        title="Update Voucher"
        open={open}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null} // This will hide the footer buttons (OK and Cancel)
      >
        <Form
          name="basic"
          layout="vertical"
          onFinish={Update_Voucher}
          autoComplete="off"
          // initialValues={selectedVoucher}
          fields={[
            {
              name: "voucherNo",
              value: selectedVoucher?.voucherNo,
            },
            // {
            //   name: "Dated",
            //   value: selectedVoucher?.Dated,
            // },
            {
              name: "Amount",
              value: selectedVoucher?.Amount,
            },
            {
              name: "Description",
              value: selectedVoucher?.Description,
            },
            {
              name: "Debit_Account_ID",
              value: selectedVoucher?.Debit_Account_ID,
            },
            {
              name: "Credit_Account_ID",
              value: selectedVoucher?.Credit_Account_ID,
            },
          ]}
        >
          <Row gutter={[16, 3]}>
            <Col span={8}>
              <Form.Item name="voucherNo" label="Voucher No">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Amount" label="Amount">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="Dated" label="Date">
                <DatePicker defaultValue={Dated} onChange={handleDate} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Debit Account"
                name="Debit_Account_ID"
                className="input-vertical"
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  // onChange={onSelectDebitAccount}
                  loading={loadingAccounts}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  //loading={accounts === false ? true : false}
                  options={accounts}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Credit Account"
                name="Credit_Account_ID"
                className="input-vertical"
              >
                <Select
                  showSearch
                  placeholder="Select Account"
                  optionFilterProp="children"
                  loading={loadingAccounts}
                  // onChange={onSelectCreditAccount}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  // loading={accounts === false ? true : false}
                  options={accounts}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item name="Description" label="Description">
                <Input />
              </Form.Item>
            </Col>

            {/* <Col span={12}>
              <Checkbox>Send Email</Checkbox>
            </Col> */}
            <Col span={24}>
              <div className="text-right">
                <Space wrap>
                  <Button
                    type="primary"
                    size="large"
                    shape="round"
                    loading={Button_Lodding}
                    htmlType="submit"
                  >
                    Update Voucher
                  </Button>
                </Space>
              </div>
            </Col>
          </Row>
        </Form>
      </Modal>

      {contextHolder}

      <div className="right-side-contents">
        {/* {loading ? <div className='spin-center'> <Space size="large">  <Spin size="large" /> </Space> </div> : */}
        <div className="page-content">
          <div className="page-header">
            <div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <NavLink to="/accounts/manage-accounts">
                  <ChevronLeftIcon style={{ width: "20px" }} />
                </NavLink>
                <span className="page-title ">{AccountIdData?.Name}</span>
                <strong className="gernal-leader"> General Ledger </strong>
                {AccountIdData?.Is_Default != "" && (
                  <CheckBadgeIcon style={{ width: "20px" }} />
                )}
              </div>
              <ul className="ledger-header">
                {AccountIdData?.AccountTypeName != "" && (
                  <>
                    <li className="vertical-divider">
                      <img src={account} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Account Type </strong>{" "}
                        <span>{AccountIdData?.AccountTypeName}</span>
                      </div>
                    </li>
                  </>
                )}

                {AccountIdData?.Balance != "" && (
                  <>
                    <li className="vertical-divider">
                      <img src={balance} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Current Balance </strong>{" "}
                        <span>
                          {AccountIdData?.Balance} ({AccountIdData?.BalanceType}
                          )
                        </span>
                      </div>
                    </li>
                  </>
                )}
                {/* {AccountIdData?.Mobile_No != "" &&
                  <>
                    <li><strong>Mobile No : </strong> <span>{AccountIdData?.Mobile_No}</span></li>
                  </>
                } */}
                {/* {AccountIdData?.Email_Address != "" &&
                  <>
                    <li><strong>Email : </strong> <span>{AccountIdData?.Email_Address}</span></li>
                  </>
                } */}
                {AccountIdData?.OpeningDate != "" && (
                  <>
                    <li>
                      <img src={calander} alt="" className="img-icon" />
                      <div className="ledger-content">
                        <strong>Opening Date </strong>{" "}
                        <span>
                          {dayjs(AccountIdData?.OpeningDate).format(
                            config.date_format
                          )}
                        </span>
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>

            <div className="header-actions">
              <Dropdown
                menu={{
                  items,
                }}
              >
                <Button type="dashed" size="large" shape="round">
                  <Space>
                    Options
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </div>
          </div>

          <div
            className="filter-wrap"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Form>
              <Form.Item>
                <RangePicker
                  allowClear
                  onChange={handleDateChange}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
          </div>
          <Table
            size="small"
            scroll={{
              x: 'max-content',
            }}
            bordered
            loading={loading}
            onChange={handleTableChange}
            dataSource={listofAccounts}
            columns={columns}
            pagination={false}
          />
          <div className="text-end mt-5">
            <Pagination
              current={pageNo}
              pageSize={pageSize}
              size="small"
              total={totalRecords}
              onChange={onPageChange}
              onShowSizeChange={onPageSizeChange} // Handle page size change
              showSizeChanger
              showQuickJumper
              showTotal={(total) => `Total ${total} items`}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default AccountLedger;
